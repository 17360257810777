import {
  createContext,
  type FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useTenantConfig } from '@/contexts/TenantConfig';
import { useCognitoAuth } from './';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';

export interface CognitoActionProps extends PropsWithChildren {
  error: Error | null;
  handleSignOut: () => Promise<void>;
  refetchUserMetadata: () => Promise<void>;
}

const stub = (): never => {
  throw new Error('You must wrap your component in <CognitoActionProvider>.');
};

export const CognitoActionContext = createContext<CognitoActionProps>({
  error: null,
  handleSignOut: async () => stub(),
  refetchUserMetadata: async () => stub(),
});

const configureAmplify = ({
  userPoolId,
  userPoolClientId,
}: {
  userPoolId: string;
  userPoolClientId: string;
}) => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId,
        userPoolClientId,
        loginWith: {
          email: true,
        },
        signUpVerificationMethod: 'link',
        userAttributes: {
          email: {
            required: true,
          },
        },
      },
    },
  });
};

export interface CognitoActionProviderProps extends PropsWithChildren {
  // The path in the application where users will be presented with the login screen.
  loginUrl: string;
}

/**
 * Cognito Action Provider enables creating and managing Cognito user sessions stored in the CognitoAuthContext,
 * via interactions with the AWS Amplify Auth library. These actions depend on Tenant configuration and GraphQL
 * API endpoints, so are separated from the underlying token and user information store.
 * @param param0
 * @returns
 */
export const CognitoActionProvider: FunctionComponent<
  CognitoActionProviderProps
> = ({ children, loginUrl }) => {
  const {
    setToken,
    setRefreshing,
    clearAuthState,
    setError: setAuthError,
  } = useCognitoAuth();
  const { replace } = useRouter();
  const { cognito } = useTenantConfig();
  const [amplifyConfigured, setAmplifyConfigured] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  /**
   * Calls the underlying Amplify `fetchUserSession` to retrieve the current user's
   * session data, and sets it to the underlying CognitoAuthContext.
   */
  const fetchUserMetadata = useCallback(
    async ({ forceRefresh = false } = {}) => {
      setAuthError(null);
      setRefreshing(true);

      try {
        const session = await fetchAuthSession({ forceRefresh });
        if (session.tokens?.idToken) {
          const idToken = session.tokens.idToken.toString();
          setToken(idToken);
        }
      } catch (err) {
        console.error(err);
        setAuthError(new Error('Failed to fetch session data.'));
      } finally {
        setRefreshing(false);
      }
    },
    [setAuthError, setRefreshing, setToken]
  );

  // When the Tenant configuration arrives, configure Amplify.
  useEffect(() => {
    if (cognito) {
      configureAmplify({
        userPoolId: cognito.userPoolId,
        userPoolClientId: cognito.clientId,
      });
      setAmplifyConfigured(true);
    }
  }, [cognito]);

  // After the Amplify SDK is configured, fetch the user's session data
  useEffect(() => {
    if (amplifyConfigured) {
      fetchUserMetadata();
    }
  }, [amplifyConfigured, fetchUserMetadata]);

  // Listen to Amplify SDK auth events to update the user's session data.
  useEffect(() => {
    const cancelHubListener = Hub.listen('auth', async ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
        case 'tokenRefresh':
          try {
            await fetchUserMetadata();
          } catch (err) {
            console.log(err);
            setError(new Error('Error handling token refresh/sign-in event.'));
          }
          break;
        case 'signedOut':
          clearAuthState();
          replace(loginUrl);
          break;
        case 'tokenRefresh_failure':
          setError(new Error('Error handling token refresh event.'));
      }
    });

    return () => cancelHubListener();
  }, [
    fetchUserMetadata,
    loginUrl,
    replace,
    setToken,
    clearAuthState,
    setError,
  ]);

  const refetchUserMetadata = useCallback(
    async () => fetchUserMetadata({ forceRefresh: true }),
    [fetchUserMetadata]
  );

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (err) {
      console.log(err);
      setError(new Error('Failed to sign out.'));
    }
  };

  return (
    <CognitoActionContext.Provider
      value={{
        error,
        handleSignOut,
        refetchUserMetadata,
      }}>
      {children}
    </CognitoActionContext.Provider>
  );
};

export const useCognitoAction = (): CognitoActionProps => {
  const context = useContext(CognitoActionContext);
  return context;
};
