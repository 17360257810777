/*
 * This file is generated by graphql-codegen. Do NOT edit directly.
 * Run `npm run graphql:caire-api` to regenerate.
 */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Date (isoformat) */
  Date: { input: any; output: any };
  /** Decimal (fixed-point) */
  Decimal: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](https://ecma-international.org/wp-content/uploads/ECMA-404_2nd_edition_december_2017.pdf). */
  JSON: { input: any; output: any };
  /** TimeZone aware UTC datetime */
  UTCDateTime: { input: any; output: any };
  UUID: { input: any; output: any };
  /** Represents NULL values */
  Void: { input: any; output: any };
  join__DirectiveArguments: { input: any; output: any };
  join__FieldSet: { input: any; output: any };
  link__Import: { input: any; output: any };
};

export type Abatement = {
  __typename?: 'Abatement';
  abatementAge?: Maybe<Age>;
  abatementDateTime?: Maybe<Scalars['String']['output']>;
  abatementPeriod?: Maybe<Period>;
  abatementRange?: Maybe<Range>;
  abatementString?: Maybe<Scalars['String']['output']>;
};

export type AbatementInput = {
  abatementAge?: InputMaybe<AgeInput>;
  abatementDateTime?: InputMaybe<Scalars['String']['input']>;
  abatementPeriod?: InputMaybe<PeriodInput>;
  abatementRange?: InputMaybe<RangeInput>;
  abatementString?: InputMaybe<Scalars['String']['input']>;
};

export type ActiveWebConversationInput = {
  patientId: Scalars['UUID']['input'];
  programId: Scalars['UUID']['input'];
  webContext: Scalars['String']['input'];
};

export enum ActorType {
  Cognito = 'COGNITO',
  Patient = 'PATIENT',
  Service = 'SERVICE',
  Staff = 'STAFF',
  System = 'SYSTEM',
}

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  state: StateCode;
};

export type AddressInput = {
  city: Scalars['String']['input'];
  country?: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  state: StateCode;
};

export type Adherence = {
  __typename?: 'Adherence';
  code: CodeableConcept;
  reason?: Maybe<CodeableConcept>;
};

export type AdherenceInput = {
  code: CodeableConceptInput;
  reason?: InputMaybe<CodeableConceptInput>;
};

export type Age = {
  __typename?: 'Age';
  unit: AgeUnit;
  value: Scalars['Int']['output'];
};

export type AgeInput = {
  unit: AgeUnit;
  value: Scalars['Int']['input'];
};

export enum AgeUnit {
  A = 'A',
  D = 'D',
  H = 'H',
  Min = 'MIN',
  Mo = 'MO',
  Wk = 'WK',
}

export type AgentConfig = {
  __typename?: 'AgentConfig';
  agentName: Scalars['String']['output'];
  spaceId: Scalars['String']['output'];
};

export type AgentConfigInput = {
  agentName: Scalars['String']['input'];
  spaceId: Scalars['String']['input'];
};

export type AgentConversation = {
  __typename?: 'AgentConversation';
  channelId: Scalars['UUID']['output'];
  channelType: ChannelType;
  createdAt: Scalars['UTCDateTime']['output'];
  deletedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  id: Scalars['UUID']['output'];
  lastMessageAt: Scalars['UTCDateTime']['output'];
  lastMessagePreview: Scalars['String']['output'];
  lastMessageSenderType: ParticipantType;
  messages: Array<AgentMessage>;
  numMessages: Scalars['Int']['output'];
  patientId?: Maybe<Scalars['UUID']['output']>;
  programId?: Maybe<Scalars['UUID']['output']>;
  status: AgentConversationStatus;
  tenantId: Scalars['UUID']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type AgentConversationMessagesArgs = {
  pagination?: InputMaybe<PaginationParams>;
};

export type AgentConversationFilters = {
  channelType?: InputMaybe<Array<ChannelType>>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<Array<AgentConversationStatus>>;
};

export enum AgentConversationStatus {
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Open = 'OPEN',
}

export type AgentConversationsList = {
  __typename?: 'AgentConversationsList';
  items: Array<AgentConversation>;
  totalCount: Scalars['Int']['output'];
};

export type AgentMessage = {
  __typename?: 'AgentMessage';
  body: Scalars['String']['output'];
  conversationId: Scalars['UUID']['output'];
  createdAt: Scalars['UTCDateTime']['output'];
  deletedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  expectation?: Maybe<BotResponseExpectation>;
  id: Scalars['UUID']['output'];
  inferenceResult?: Maybe<InferenceResult>;
  patientId?: Maybe<Scalars['UUID']['output']>;
  programId?: Maybe<Scalars['UUID']['output']>;
  recipientId?: Maybe<Scalars['UUID']['output']>;
  recipientType: ParticipantType;
  responseId?: Maybe<Scalars['UUID']['output']>;
  senderId?: Maybe<Scalars['UUID']['output']>;
  senderType: ParticipantType;
  tenantId: Scalars['UUID']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type AgentMessageParams = {
  body: Scalars['String']['input'];
  recipientId?: InputMaybe<Scalars['UUID']['input']>;
  recipientType: ParticipantType;
  senderId?: InputMaybe<Scalars['UUID']['input']>;
  senderType: ParticipantType;
};

export type AgentMessagesList = {
  __typename?: 'AgentMessagesList';
  items: Array<AgentMessage>;
  totalCount: Scalars['Int']['output'];
};

export type Alert = {
  __typename?: 'Alert';
  acknowledged: Scalars['Boolean']['output'];
  acknowledgedBy?: Maybe<Scalars['UUID']['output']>;
  careJourneyId?: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  monitor: Monitor;
  monitorId: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  note: Scalars['String']['output'];
  patientId: Scalars['UUID']['output'];
  patientMessage: Scalars['String']['output'];
  priority: MonitorPriority;
  programId?: Maybe<Scalars['UUID']['output']>;
  staffClinicalMessage: Scalars['String']['output'];
  staffNonclinicalMessage: Scalars['String']['output'];
  tenantId: Scalars['UUID']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type AlertCountsList = {
  __typename?: 'AlertCountsList';
  items: Array<PatientAlertCounts>;
  totalCount: Scalars['Int']['output'];
};

export type AlertList = {
  __typename?: 'AlertList';
  items: Array<Alert>;
  totalCount: Scalars['Int']['output'];
};

export enum AlertSortField {
  CreatedAt = 'CREATED_AT',
  Priority = 'PRIORITY',
}

export type AllergyAutocompleteResult = {
  __typename?: 'AllergyAutocompleteResult';
  genericRxcui: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  rxcui: Scalars['Int']['output'];
};

export type AllergyIntolerance = {
  __typename?: 'AllergyIntolerance';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  patientId: Scalars['UUID']['output'];
  programId: Scalars['UUID']['output'];
  resource: AllergyIntoleranceResource;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export enum AllergyIntoleranceCategory {
  Biologic = 'BIOLOGIC',
  Environment = 'ENVIRONMENT',
  Food = 'FOOD',
  Medication = 'MEDICATION',
}

export enum AllergyIntoleranceCriticality {
  High = 'HIGH',
  Low = 'LOW',
  UnableToAssess = 'UNABLE_TO_ASSESS',
}

export type AllergyIntoleranceFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
};

export type AllergyIntoleranceResource = {
  __typename?: 'AllergyIntoleranceResource';
  category?: Maybe<Array<AllergyIntoleranceCategory>>;
  clinicalStatus?: Maybe<CodeableConcept>;
  code?: Maybe<CodeableConcept>;
  criticality?: Maybe<AllergyIntoleranceCriticality>;
  encounter?: Maybe<Reference>;
  identifier?: Maybe<Array<Identifier>>;
  lastOccurrence?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Array<Annotation>>;
  onset?: Maybe<Onset>;
  participant?: Maybe<Array<Participant>>;
  patient: Reference;
  reaction?: Maybe<Array<Reaction>>;
  recordedDate?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CodeableConcept>;
  verificationStatus?: Maybe<CodeableConcept>;
};

export type AllergyIntoleranceResourceInput = {
  category?: InputMaybe<Array<AllergyIntoleranceCategory>>;
  clinicalStatus?: InputMaybe<CodeableConceptInput>;
  code?: InputMaybe<CodeableConceptInput>;
  criticality?: InputMaybe<AllergyIntoleranceCriticality>;
  encounter?: InputMaybe<ReferenceInput>;
  identifier?: InputMaybe<Array<IdentifierInput>>;
  lastOccurrence?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Array<AnnotationInput>>;
  onset?: InputMaybe<OnsetInput>;
  participant?: InputMaybe<Array<ParticipantInput>>;
  patient: ReferenceInput;
  reaction?: InputMaybe<Array<ReactionInput>>;
  recordedDate?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CodeableConceptInput>;
  verificationStatus?: InputMaybe<CodeableConceptInput>;
};

export enum AllergyIntoleranceServerity {
  Mild = 'MILD',
  Moderate = 'MODERATE',
  Severe = 'SEVERE',
}

export enum AnchorType {
  AfterEnrollment = 'AFTER_ENROLLMENT',
  AfterEvent = 'AFTER_EVENT',
  AfterTouchpoint = 'AFTER_TOUCHPOINT',
  BeforeEvent = 'BEFORE_EVENT',
}

export type Annotation = {
  __typename?: 'Annotation';
  author?: Maybe<Author>;
  text: Scalars['String']['output'];
  time?: Maybe<Scalars['String']['output']>;
};

export type AnnotationInput = {
  author?: InputMaybe<AuthorInput>;
  text: Scalars['String']['input'];
  time?: InputMaybe<Scalars['String']['input']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  appointmentType: AppointmentType;
  appointmentTypeId: Scalars['UUID']['output'];
  createdAt: Scalars['UTCDateTime']['output'];
  createdBy: Scalars['String']['output'];
  createdByType: ActorType;
  description: Scalars['String']['output'];
  encounter?: Maybe<Encounter>;
  endTime: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  patient: Patient;
  patientId: Scalars['UUID']['output'];
  preFormTaskId?: Maybe<Scalars['UUID']['output']>;
  programId: Scalars['UUID']['output'];
  staff: Staff;
  staffId: Scalars['UUID']['output'];
  startTime: Scalars['UTCDateTime']['output'];
  status: AppointmentStatus;
  summary: Scalars['String']['output'];
  taskId?: Maybe<Scalars['UUID']['output']>;
  timezone: Scalars['String']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
  updatedBy: Scalars['String']['output'];
  updatedByType: ActorType;
  videoConferenceUrl?: Maybe<Scalars['String']['output']>;
};

export enum AppointmentSortField {
  CreatedAt = 'CREATED_AT',
  StartTime = 'START_TIME',
}

export enum AppointmentStatus {
  Booked = 'BOOKED',
  Cancelled = 'CANCELLED',
  Fulfilled = 'FULFILLED',
  Noshow = 'NOSHOW',
}

export type AppointmentType = {
  __typename?: 'AppointmentType';
  allowedRole: StaffRole;
  bookingNoticeLimit: Scalars['Int']['output'];
  createdAt: Scalars['UTCDateTime']['output'];
  descriptionTemplate: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  postFormId?: Maybe<Scalars['UUID']['output']>;
  preFormTaskTemplateId?: Maybe<Scalars['UUID']['output']>;
  programId: Scalars['UUID']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type AssessmentNote = {
  __typename?: 'AssessmentNote';
  icd10Codes?: Maybe<Array<Icd10Code>>;
  note?: Maybe<Scalars['String']['output']>;
};

export type AssessmentNoteInput = {
  icd10Codes?: InputMaybe<Array<Icd10CodeInput>>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type Author = {
  __typename?: 'Author';
  authorReference: Reference;
  authorString: Scalars['String']['output'];
};

export type AuthorInput = {
  authorReference: ReferenceInput;
  authorString: Scalars['String']['input'];
};

export type BackfillEnrollmentInput = {
  createdAt: Scalars['UTCDateTime']['input'];
  eligibilityType: EligibilityType;
  enrolledAt: Scalars['UTCDateTime']['input'];
  enrolledUntil?: InputMaybe<Scalars['UTCDateTime']['input']>;
  organizationEmployeeId?: InputMaybe<Scalars['UUID']['input']>;
  organizationId: Scalars['UUID']['input'];
  payerType: PayerType;
  programId: Scalars['UUID']['input'];
  status: EnrollmentStatus;
  unenrolledAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  updatedAt: Scalars['UTCDateTime']['input'];
};

export type BackfillPatientInput = {
  addresses: Array<AddressInput>;
  birthDate: Scalars['Date']['input'];
  birthSex: BirthSex;
  contactPreference?: InputMaybe<ContactPreference>;
  createdAt: Scalars['UTCDateTime']['input'];
  emailAddress: EmailAddressInput;
  enrollment?: InputMaybe<BackfillEnrollmentInput>;
  firstName: Scalars['String']['input'];
  genderIdentity?: InputMaybe<GenderIdentityInput>;
  id: Scalars['UUID']['input'];
  lastName: Scalars['String']['input'];
  phoneNumbers?: InputMaybe<Array<PhoneNumberInput>>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<PronounsInput>;
  updatedAt: Scalars['UTCDateTime']['input'];
};

export type BasicStaff = {
  __typename?: 'BasicStaff';
  authId?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['UTCDateTime']['output'];
  deaNumber?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<EmailAddress>;
  firstName: Scalars['String']['output'];
  genderIdentity?: Maybe<GenderIdentity>;
  id: Scalars['UUID']['output'];
  lastName: Scalars['String']['output'];
  npiNumber?: Maybe<Scalars['String']['output']>;
  preferredName?: Maybe<Scalars['String']['output']>;
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  pronouns?: Maybe<Pronouns>;
  role: StaffRole;
  status: StaffStatus;
  tenantLevelRoles?: Maybe<Array<TenantRole>>;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type BatchCreateValuesInput = {
  data: Array<ValidateValuesInput>;
  patientId: Scalars['UUID']['input'];
  programId: Scalars['UUID']['input'];
};

export type BatchValidateValuesInput = {
  data: Array<ValidateValuesInput>;
  programId: Scalars['UUID']['input'];
};

export enum BirthSex {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER',
  Unknown = 'UNKNOWN',
}

export type BotResponse = {
  __typename?: 'BotResponse';
  createdAt?: Maybe<Scalars['UTCDateTime']['output']>;
  expectation?: Maybe<BotResponseExpectation>;
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  thread: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['UTCDateTime']['output']>;
};

export type BotResponseExpectation = {
  __typename?: 'BotResponseExpectation';
  hint?: Maybe<ExpectationHint>;
  options?: Maybe<Array<BotResponseOption>>;
  strict: Scalars['Boolean']['output'];
  type?: Maybe<ExpectationType>;
};

export type BotResponseOption = {
  __typename?: 'BotResponseOption';
  id?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type BotResponseOutput = {
  __typename?: 'BotResponseOutput';
  messages?: Maybe<Array<BotResponse>>;
};

export type BoundingBoxInput = {
  maxLatitude: Scalars['Float']['input'];
  maxLongitude: Scalars['Float']['input'];
  minLatitude: Scalars['Float']['input'];
  minLongitude: Scalars['Float']['input'];
};

export type BrandingConfig = {
  __typename?: 'BrandingConfig';
  logo: Scalars['String']['output'];
  primaryColor: Scalars['String']['output'];
  secondaryColor: Scalars['String']['output'];
};

export type BrandingConfigInput = {
  logo: Scalars['String']['input'];
  primaryColor: Scalars['String']['input'];
  secondaryColor: Scalars['String']['input'];
};

export type CancelAppointmentInput = {
  taskIgnore?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CareJourney = {
  __typename?: 'CareJourney';
  careJourneyTemplateVersion: CareJourneyTemplateVersion;
  careJourneyTemplateVersionId: Scalars['UUID']['output'];
  createdAt: Scalars['UTCDateTime']['output'];
  enrollmentDate?: Maybe<Scalars['UTCDateTime']['output']>;
  eventDate?: Maybe<Scalars['UTCDateTime']['output']>;
  id: Scalars['UUID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  offboardedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  patient: Patient;
  patientId: Scalars['UUID']['output'];
  previousCareJourneyId?: Maybe<Scalars['UUID']['output']>;
  programId: Scalars['UUID']['output'];
  retiredAt?: Maybe<Scalars['UTCDateTime']['output']>;
  sequentialTouchpoints?: Maybe<Array<SequentialTouchpoint>>;
  status: CareJourneyStatus;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type CareJourneySequentialTouchpointsArgs = {
  touchpointFilters?: InputMaybe<FilterSequentialTouchpointParams>;
  touchpointSorting?: InputMaybe<Array<SortTouchpointParam>>;
};

export type CareJourneyHealth = {
  __typename?: 'CareJourneyHealth';
  status: Scalars['String']['output'];
};

export enum CareJourneyStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Offboarded = 'OFFBOARDED',
  OptedOut = 'OPTED_OUT',
  Pending = 'PENDING',
}

export type CareJourneyTemplate = {
  __typename?: 'CareJourneyTemplate';
  archivedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  blueprint: Scalars['Boolean']['output'];
  createdAt: Scalars['UTCDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  draftVersion: CareJourneyTemplateVersion;
  id: Scalars['UUID']['output'];
  modality: Modality;
  name: Scalars['String']['output'];
  programId: Scalars['UUID']['output'];
  publishedVersion?: Maybe<CareJourneyTemplateVersion>;
  slug: Scalars['String']['output'];
  status: CareJourneyTemplateStatus;
  type: CareJourneyType;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type CareJourneyTemplateFilters = {
  blueprint?: InputMaybe<Scalars['Boolean']['input']>;
  includeArchived?: Scalars['Boolean']['input'];
  modality?: InputMaybe<Modality>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<CareJourneyTemplateStatus>;
  type?: InputMaybe<CareJourneyType>;
};

export enum CareJourneyTemplateStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Suspended = 'SUSPENDED',
}

export type CareJourneyTemplateVersion = {
  __typename?: 'CareJourneyTemplateVersion';
  careJourneyTemplate: CareJourneyTemplate;
  createdAt: Scalars['UTCDateTime']['output'];
  duration: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  programId: Scalars['UUID']['output'];
  publishedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  touchpointTemplates: Array<SequentialTouchpointTemplate>;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export enum CareJourneyType {
  EventDriven = 'EVENT_DRIVEN',
  Ongoing = 'ONGOING',
  Preventative = 'PREVENTATIVE',
}

export enum ChannelType {
  Sms = 'SMS',
  Voice = 'VOICE',
  WebChat = 'WEB_CHAT',
}

export type CheckEmploymentEligibilityInput = {
  birthDate: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  workEmail?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CheckEmploymentEligibilityOutput = {
  __typename?: 'CheckEmploymentEligibilityOutput';
  ineligibleReason?: Maybe<IneligibleReason>;
  isEligible: Scalars['Boolean']['output'];
  organizationEmployeeId?: Maybe<Scalars['UUID']['output']>;
  organizationPrograms: Array<OrganizationProgram>;
};

export type ClinicalNote = {
  __typename?: 'ClinicalNote';
  body?: Maybe<SoapNoteHealthCoachNotePrescriberNote>;
  createdAt: Scalars['UTCDateTime']['output'];
  createdBy: Scalars['UUID']['output'];
  encounterId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  patient: Patient;
  patientId: Scalars['UUID']['output'];
  programId: Scalars['UUID']['output'];
  staff: Staff;
  staffId: Scalars['UUID']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export enum ClinicalNoteType {
  HealthCoachNote = 'HEALTH_COACH_NOTE',
  PrescriberNote = 'PRESCRIBER_NOTE',
  Soapnote = 'SOAPNOTE',
}

export type CodeableConcept = {
  __typename?: 'CodeableConcept';
  coding?: Maybe<Array<Coding>>;
  text?: Maybe<Scalars['String']['output']>;
};

export type CodeableConceptFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  codeableConcept: CodeableConceptInput;
  patientId: Scalars['UUID']['input'];
  programId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CodeableConceptInput = {
  coding?: InputMaybe<Array<CodingInput>>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CodeableReference = {
  __typename?: 'CodeableReference';
  concept: CodeableConcept;
};

export type CodeableReferenceInput = {
  concept: CodeableConceptInput;
};

export type Coding = {
  __typename?: 'Coding';
  code?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  system?: Maybe<Scalars['String']['output']>;
  userSelected?: Maybe<Scalars['Boolean']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type CodingInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  display?: InputMaybe<Scalars['String']['input']>;
  system?: InputMaybe<Scalars['String']['input']>;
  userSelected?: InputMaybe<Scalars['Boolean']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type CognitoConfig = {
  __typename?: 'CognitoConfig';
  patientsClientId: Scalars['String']['output'];
  patientsUserPoolId: Scalars['String']['output'];
  staffClientId: Scalars['String']['output'];
  staffUserPoolId: Scalars['String']['output'];
};

export enum Comparator {
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  GreaterThan = 'GREATER_THAN',
  LessOrEqual = 'LESS_OR_EQUAL',
  LessThan = 'LESS_THAN',
  Sufficient = 'SUFFICIENT',
}

export type CompoundCondition = {
  __typename?: 'CompoundCondition';
  operands: Array<SimpleConditionCompoundCondition>;
  operator: CompoundOperator;
  type: ConditionType;
};

export enum CompoundOperator {
  And = 'AND',
  Or = 'OR',
}

export type ConceptSearchResult = {
  __typename?: 'ConceptSearchResult';
  id: Scalars['UUID']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ConceptsPractitionersSearchResults = {
  __typename?: 'ConceptsPractitionersSearchResults';
  concepts: Array<ConceptSearchResult>;
  practitioners: Array<PractitionerSearchResult>;
};

export type Condition = {
  __typename?: 'Condition';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  patientId: Scalars['UUID']['output'];
  programId: Scalars['UUID']['output'];
  resource: ConditionResource;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export enum ConditionClinicalStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Recurrence = 'RECURRENCE',
  Relapse = 'RELAPSE',
  Remission = 'REMISSION',
  Resolved = 'RESOLVED',
  Unknown = 'UNKNOWN',
}

export type ConditionFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
};

export type ConditionResource = {
  __typename?: 'ConditionResource';
  abatement?: Maybe<Abatement>;
  bodySite?: Maybe<Array<CodeableConcept>>;
  category?: Maybe<Array<CodeableConcept>>;
  clinicalStatus: ConditionClinicalStatus;
  code?: Maybe<CodeableConcept>;
  evidence?: Maybe<Array<CodeableReference>>;
  identifier?: Maybe<Array<Identifier>>;
  note?: Maybe<Array<Annotation>>;
  onset?: Maybe<Onset>;
  participant?: Maybe<Array<Participant>>;
  recordedDate?: Maybe<Scalars['String']['output']>;
  severity?: Maybe<CodeableConcept>;
  stage?: Maybe<Array<Stage>>;
  subject: Reference;
  verificationStatus?: Maybe<CodeableConcept>;
};

export type ConditionResourceInput = {
  abatement?: InputMaybe<AbatementInput>;
  bodySite?: InputMaybe<Array<CodeableConceptInput>>;
  category?: InputMaybe<Array<CodeableConceptInput>>;
  clinicalStatus: ConditionClinicalStatus;
  code?: InputMaybe<CodeableConceptInput>;
  evidence?: InputMaybe<Array<CodeableReferenceInput>>;
  identifier?: InputMaybe<Array<IdentifierInput>>;
  note?: InputMaybe<Array<AnnotationInput>>;
  onset?: InputMaybe<OnsetInput>;
  participant?: InputMaybe<Array<ParticipantInput>>;
  recordedDate?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<CodeableConceptInput>;
  stage?: InputMaybe<Array<StageInput>>;
  subject: ReferenceInput;
  verificationStatus?: InputMaybe<CodeableConceptInput>;
};

export enum ConditionType {
  Compound = 'COMPOUND',
  Simple = 'SIMPLE',
}

export enum ContactPreference {
  Email = 'EMAIL',
  Sms = 'SMS',
}

export enum ContactUse {
  Home = 'HOME',
  Mobile = 'MOBILE',
  Temp = 'TEMP',
  Work = 'WORK',
}

export type ContentHealth = {
  __typename?: 'ContentHealth';
  status: Scalars['String']['output'];
};

export type ContentView = {
  __typename?: 'ContentView';
  contentId: Scalars['String']['output'];
  contentType: ContentfulContentType;
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  isContentAssignmentComplete: Scalars['Boolean']['output'];
  parentContentId?: Maybe<Scalars['String']['output']>;
  parentContentType?: Maybe<ContentfulContentType>;
  patientId: Scalars['UUID']['output'];
  programId: Scalars['UUID']['output'];
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars['UUID']['output']>;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export enum ContentfulContentType {
  GuidedJourney = 'GUIDED_JOURNEY',
  Lesson = 'LESSON',
  Page = 'PAGE',
  PatientForm = 'PATIENT_FORM',
}

export type Conversation = {
  __typename?: 'Conversation';
  conversationParticipants?: Maybe<Array<ConversationParticipant>>;
  createdAt: Scalars['UTCDateTime']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  programId: Scalars['UUID']['output'];
  status: ConversationStatus;
  subject: Scalars['String']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type ConversationAiHealth = {
  __typename?: 'ConversationAIHealth';
  status: Scalars['String']['output'];
};

export type ConversationParticipant = {
  __typename?: 'ConversationParticipant';
  conversationId: Scalars['UUID']['output'];
  conversationUser: ConversationUser;
  conversationUserId: Scalars['UUID']['output'];
  createdAt: Scalars['UTCDateTime']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  status: ConversationParticipantStatus;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export enum ConversationParticipantStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum ConversationSortField {
  LastMessageAt = 'LAST_MESSAGE_AT',
  Status = 'STATUS',
}

export enum ConversationStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export type ConversationUser = {
  __typename?: 'ConversationUser';
  createdAt: Scalars['UTCDateTime']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  status: ConversationUserStatus;
  updatedAt: Scalars['UTCDateTime']['output'];
  userId: Scalars['UUID']['output'];
  userType: ConversationUserType;
};

export type ConversationUserAccessToken = {
  __typename?: 'ConversationUserAccessToken';
  accessToken: Scalars['String']['output'];
  expiredAt: Scalars['Int']['output'];
};

export enum ConversationUserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum ConversationUserType {
  Patient = 'PATIENT',
  Staff = 'STAFF',
}

export type CreateAgentConversationInput = {
  channelId: Scalars['UUID']['input'];
  channelType: ChannelType;
  message: AgentMessageParams;
  patientId: Scalars['UUID']['input'];
  programId: Scalars['UUID']['input'];
};

export type CreateAgentMessageInput = {
  agentMetadata?: InputMaybe<CreateAgentMetadataInput>;
  body: Scalars['String']['input'];
  conversationId: Scalars['UUID']['input'];
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
  recipientId?: InputMaybe<Scalars['UUID']['input']>;
  recipientType: ParticipantType;
  senderId?: InputMaybe<Scalars['UUID']['input']>;
  senderType: ParticipantType;
};

export type CreateAgentMetadataInput = {
  langchainDurationMs: Scalars['Float']['input'];
  langchainTraceId: Scalars['String']['input'];
  rasaMetadata: CreateRasaMetadataInput;
};

export type CreateAllergyIntoleranceInput = {
  active?: Scalars['Boolean']['input'];
  patientId: Scalars['UUID']['input'];
  programId: Scalars['UUID']['input'];
  resource: AllergyIntoleranceResourceInput;
};

export type CreateAppointmentInput = {
  appointmentTypeId: Scalars['UUID']['input'];
  backfill?: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  patientId: Scalars['UUID']['input'];
  preFormTaskId?: InputMaybe<Scalars['UUID']['input']>;
  programId: Scalars['UUID']['input'];
  staffId: Scalars['UUID']['input'];
  startTime: Scalars['UTCDateTime']['input'];
  status?: InputMaybe<AppointmentStatus>;
  taskId?: InputMaybe<Scalars['UUID']['input']>;
  timezone: Scalars['String']['input'];
};

export type CreateCareJourney = {
  __typename?: 'CreateCareJourney';
  careJourneyTemplateVersionId: Scalars['UUID']['output'];
  createdAt: Scalars['UTCDateTime']['output'];
  enrollmentDate?: Maybe<Scalars['UTCDateTime']['output']>;
  eventDate?: Maybe<Scalars['UTCDateTime']['output']>;
  id: Scalars['UUID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  offboardedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  patientId: Scalars['UUID']['output'];
  previousCareJourneyId?: Maybe<Scalars['UUID']['output']>;
  programId: Scalars['UUID']['output'];
  retiredAt?: Maybe<Scalars['UTCDateTime']['output']>;
  sequentialTouchpoints?: Maybe<Array<UpdatedSequentialTouchpoint>>;
  status: CareJourneyStatus;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type CreateCareJourneyInput = {
  careJourneyTemplateVersionId: Scalars['UUID']['input'];
  eventDate?: InputMaybe<Scalars['UTCDateTime']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  offboardedAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  patientId: Scalars['UUID']['input'];
  previousCareJourneyId?: InputMaybe<Scalars['UUID']['input']>;
  programId: Scalars['UUID']['input'];
  retiredAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  status?: InputMaybe<CareJourneyStatus>;
};

export type CreateCareJourneyTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Int']['input'];
  modality: Modality;
  name: Scalars['String']['input'];
  programId: Scalars['UUID']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
  type: CareJourneyType;
};

export type CreateChildRunInfoInput = {
  durationMs: Scalars['Float']['input'];
  endTime: Scalars['String']['input'];
  name: Scalars['String']['input'];
  runId: Scalars['String']['input'];
  runType: Scalars['String']['input'];
  startTime: Scalars['String']['input'];
};

export type CreateConditionInput = {
  patientId: Scalars['UUID']['input'];
  programId: Scalars['UUID']['input'];
  resource: ConditionResourceInput;
};

export type CreateContentViewInput = {
  contentId: Scalars['String']['input'];
  contentType: ContentfulContentType;
  isContentAssignmentComplete?: Scalars['Boolean']['input'];
  parentContentId?: InputMaybe<Scalars['String']['input']>;
  parentContentType?: InputMaybe<ContentfulContentType>;
  patientId: Scalars['UUID']['input'];
  programId: Scalars['UUID']['input'];
  taskId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateConversationAiMessageInput = {
  text: Scalars['String']['input'];
  thread: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CreateCronofyElementTokenInput = {
  cronofyPermissions: Array<CronofyPermission>;
  staffId: Scalars['UUID']['input'];
};

export type CreateCustomObservationDefinitionInput = {
  programId: Scalars['UUID']['input'];
  resource: CustomObservationDefinitionResourceInput;
};

export type CreateDeepLinkInput = {
  expiryDuration: DeepLinkDuration;
  patientId: Scalars['UUID']['input'];
  programId: Scalars['UUID']['input'];
  target: Scalars['JSON']['input'];
  tenantId: Scalars['UUID']['input'];
};

export type CreateEncounterInput = {
  appointmentId?: InputMaybe<Scalars['UUID']['input']>;
  clinicalNoteType?: InputMaybe<ClinicalNoteType>;
  patientId: Scalars['UUID']['input'];
  programId: Scalars['UUID']['input'];
  staffId: Scalars['UUID']['input'];
  type: EncounterType;
};

export type CreateEnrollmentInput = {
  employeeDetails: EligibilityCheckInput;
  enrolledUntil?: InputMaybe<Scalars['UTCDateTime']['input']>;
  patientId: Scalars['UUID']['input'];
  programId: Scalars['UUID']['input'];
};

export type CreateFacilityParams = {
  accessible?: InputMaybe<Scalars['Boolean']['input']>;
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  state: Scalars['String']['input'];
  type: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type CreateFormInput = {
  config: Scalars['JSON']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  programId: Scalars['UUID']['input'];
  publish?: Scalars['Boolean']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInputDataInput = {
  numResults: Scalars['Int']['input'];
  question: Scalars['String']['input'];
  serviceLine: Scalars['String']['input'];
  spaceId: Scalars['String']['input'];
};

export type CreateMedicationStatementInput = {
  active?: Scalars['Boolean']['input'];
  patientId: Scalars['UUID']['input'];
  programId: Scalars['UUID']['input'];
  resource: MedicationStatementResourceInput;
};

export type CreateMonitorInput = {
  audience?: Array<MonitorAudience>;
  careJourneyId?: InputMaybe<Scalars['UUID']['input']>;
  muted?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  patientMessageTemplate?: Scalars['String']['input'];
  priority?: MonitorPriority;
  programId?: InputMaybe<Scalars['UUID']['input']>;
  rules?: InputMaybe<Scalars['JSON']['input']>;
  scope?: MonitorScope;
  staffClinicalMessageTemplate?: Scalars['String']['input'];
  staffNonclinicalMessageTemplate?: Scalars['String']['input'];
  type?: MonitorType;
};

export type CreateObservationDefinitionInput = {
  programId: Scalars['UUID']['input'];
  resource: ObservationDefinitionResourceInput;
};

export type CreateObservationInput = {
  patientId: Scalars['UUID']['input'];
  programId: Scalars['UUID']['input'];
  resource: ObservationResourceInput;
};

export type CreateOrganization = {
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type CreateOrganizationEmployee = {
  birthDate: Scalars['Date']['input'];
  externalEmployeeId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  healthBenefitEligibilityStatus: OrganizationEmployeeStatus;
  isDependent?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  lastSeenEligibilityDate?: InputMaybe<Scalars['Date']['input']>;
  organizationId: Scalars['UUID']['input'];
  /** Must be a valid email address */
  workEmail?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export type CreateOrganizationProgram = {
  maxEnrollmentsPerEmployee: Scalars['Int']['input'];
  organizationId: Scalars['UUID']['input'];
  programId: Scalars['UUID']['input'];
};

export type CreatePatientInput = {
  addresses?: InputMaybe<Array<AddressInput>>;
  birthDate: Scalars['Date']['input'];
  birthSex: BirthSex;
  contactPreference?: InputMaybe<ContactPreference>;
  firstName: Scalars['String']['input'];
  genderIdentity?: InputMaybe<GenderIdentityInput>;
  lastName: Scalars['String']['input'];
  phoneNumbers?: InputMaybe<Array<PhoneNumberInput>>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<PronounsInput>;
  verified?: Scalars['Boolean']['input'];
};

export type CreatePractitionerAddressParams = {
  acceptingNewPatients?: InputMaybe<Scalars['Boolean']['input']>;
  accessible?: InputMaybe<Scalars['Boolean']['input']>;
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  practitionerId: Scalars['UUID']['input'];
  schedulingUrl?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  telemedicineCapable?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode: Scalars['String']['input'];
};

export type CreatePractitionerNetworkParams = {
  networkId: Scalars['UUID']['input'];
  practitionerId: Scalars['UUID']['input'];
};

export type CreatePractitionerParams = {
  boardCertifications?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName: Scalars['String']['input'];
  includeInSearch?: Scalars['Boolean']['input'];
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName: Scalars['String']['input'];
  npiNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePractitionerSpecialtyParams = {
  practitionerId: Scalars['UUID']['input'];
  primary: Scalars['Boolean']['input'];
  specialtyId: Scalars['UUID']['input'];
};

export type CreateProfessionalLicenseInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['Date']['input']>;
  licenseNumber: Scalars['String']['input'];
  licenseState: StateCode;
  staffId: Scalars['UUID']['input'];
  type: ProfessionalLicenseType;
};

export type CreateProgramInput = {
  brandingConfig?: InputMaybe<BrandingConfigInput>;
  config?: InputMaybe<ProgramConfigInput>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  status?: ProgramStatus;
  twilioMetadata?: InputMaybe<TwilioMetadataInput>;
};

export type CreateRasaMetadataInput = {
  agentOutput: Scalars['String']['input'];
  bedrockLatency: Scalars['Int']['input'];
  bedrockRequestId: Scalars['String']['input'];
  langsmithChildRunInfo: Array<CreateChildRunInfoInput>;
  langsmithTraceEndTime: Scalars['String']['input'];
  langsmithTraceMetadata: CreateTraceMetadataInput;
  langsmithTraceStartTime: Scalars['String']['input'];
};

export type CreateSequentialTouchpointInput = {
  careJourneyId: Scalars['UUID']['input'];
  completedAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  dueAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  programId: Scalars['UUID']['input'];
  sequentialTouchpointTemplateId: Scalars['UUID']['input'];
  status: TouchpointStatus;
};

export type CreateSequentialTouchpointTemplateInput = {
  anchor?: InputMaybe<AnchorType>;
  anchorOffset?: InputMaybe<Scalars['Int']['input']>;
  anchorTouchpointTemplateId?: InputMaybe<Scalars['UUID']['input']>;
  careJourneyTemplateVersionId: Scalars['UUID']['input'];
  category: TouchpointCategory;
  confirmedMessage?: InputMaybe<Scalars['String']['input']>;
  declinedMessage?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  initialMessage?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notificationConfig?: InputMaybe<Array<TouchpointNotificationConfigInput>>;
  openUponEnrollment?: InputMaybe<Scalars['Boolean']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  programId: Scalars['UUID']['input'];
  reopenOnEventDateUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  rules?: InputMaybe<Scalars['JSON']['input']>;
  taskConfig?: InputMaybe<TouchpointTemplateTaskConfigInput>;
  taskFrequency?: InputMaybe<TaskCadenceInput>;
  taskTemplateId: Scalars['UUID']['input'];
  touchpointFrequency?: InputMaybe<TouchpointCadenceInput>;
  type?: InputMaybe<TouchpointType>;
};

export type CreateStaffInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  deaNumber?: InputMaybe<Scalars['String']['input']>;
  emailAddress: EmailAddressInput;
  firstName: Scalars['String']['input'];
  genderIdentity?: InputMaybe<GenderIdentityInput>;
  lastName: Scalars['String']['input'];
  npiNumber?: InputMaybe<Scalars['String']['input']>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  professionalLicenses: Array<CreateStaffProfessionalLicenseInput>;
  programId: Scalars['UUID']['input'];
  pronouns?: InputMaybe<PronounsInput>;
  role: StaffRole;
  tenantLevelRoles?: InputMaybe<Array<TenantRole>>;
};

export type CreateStaffProfessionalLicenseInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['Date']['input']>;
  licenseNumber: Scalars['String']['input'];
  licenseState: StateCode;
  type: ProfessionalLicenseType;
};

export type CreateStaffProgramInput = {
  programId: Scalars['UUID']['input'];
  staffId: Scalars['UUID']['input'];
};

export type CreateTaskInput = {
  assigneeId: Scalars['UUID']['input'];
  category?: InputMaybe<TaskCategory>;
  comments?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  eventDueAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  legacyNotificationSchedule?: InputMaybe<NotificationScheduleInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  notificationConfig?: InputMaybe<Array<NotificationConfigInput>>;
  patientId: Scalars['UUID']['input'];
  priority?: InputMaybe<TaskPriority>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
  relatedObjectId?: InputMaybe<Scalars['String']['input']>;
  relatedObjectType?: InputMaybe<TaskRelatedObject>;
  reporterId?: InputMaybe<Scalars['UUID']['input']>;
  resultObjectId?: InputMaybe<Scalars['UUID']['input']>;
  resultObjectType?: InputMaybe<TaskResultObject>;
  role?: InputMaybe<StaffRole>;
  status: TaskStatus;
  taskConfig?: InputMaybe<TaskConfigInput>;
  taskDueAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  taskExpireAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  taskTemplateId?: InputMaybe<Scalars['UUID']['input']>;
  taskType?: InputMaybe<TaskType>;
};

export type CreateTaskTemplateInput = {
  archivedAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  category: TaskCategory;
  description?: InputMaybe<Scalars['String']['input']>;
  eventDueDuration?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  notificationConfig?: InputMaybe<NotificationReminderConfigInput>;
  priority: TaskPriority;
  programId: Scalars['UUID']['input'];
  relatedObjectId?: InputMaybe<Scalars['String']['input']>;
  relatedObjectType?: InputMaybe<TaskRelatedObject>;
  resultObjectType?: InputMaybe<TaskResultObject>;
  role?: InputMaybe<StaffRole>;
  slug: Scalars['String']['input'];
  taskConfig: TaskConfigInput;
  taskDueDuration: Scalars['Int']['input'];
  taskExpirationDuration?: InputMaybe<Scalars['Int']['input']>;
  taskType: TaskType;
};

export type CreateTenantInput = {
  agentConfig?: InputMaybe<AgentConfigInput>;
  brandingConfig?: InputMaybe<BrandingConfigInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  schedulingType?: SchedulingType;
  slug: Scalars['String']['input'];
  status?: TenantStatus;
  twilioConfig?: InputMaybe<TwilioConfigInput>;
};

export type CreateTraceMetadataInput = {
  config: Scalars['String']['input'];
  embeddingModel: Scalars['String']['input'];
  input: CreateInputDataInput;
  llmModel: Scalars['String']['input'];
  lsMethod: Scalars['String']['input'];
};

export type CreateWebAgentMessageInput = {
  body: Scalars['String']['input'];
  patientId: Scalars['UUID']['input'];
  programId: Scalars['UUID']['input'];
  webContext: Scalars['String']['input'];
};

export type CronofyElementToken = {
  __typename?: 'CronofyElementToken';
  cronofyPermissions: Array<CronofyPermission>;
  expiresIn: Scalars['Int']['output'];
  externalUserId: Scalars['String']['output'];
  origin: Scalars['String']['output'];
  staffId: Scalars['UUID']['output'];
  token: Scalars['String']['output'];
};

export enum CronofyPermission {
  Agenda = 'AGENDA',
  Availability = 'AVAILABILITY',
}

export type CronofyProfile = {
  __typename?: 'CronofyProfile';
  createdAt: Scalars['UTCDateTime']['output'];
  externalUserId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  primaryCalendarId: Scalars['String']['output'];
  staffId: Scalars['UUID']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type CustomObservationDefinitionResourceInput = {
  components?: InputMaybe<Array<ObservationDefinitionItemInput>>;
  dataConfig: Scalars['JSON']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export enum DataType {
  Bool = 'BOOL',
  Choice = 'CHOICE',
  CodedAutocomplete = 'CODED_AUTOCOMPLETE',
  CodedChoice = 'CODED_CHOICE',
  Composite = 'COMPOSITE',
  Integer = 'INTEGER',
  IntegerChoice = 'INTEGER_CHOICE',
  Quantity = 'QUANTITY',
  String = 'STRING',
}

export type DeepLinkDuration = {
  unit: DurationUnit;
  value: Scalars['Int']['input'];
};

export type DeepLinkPathTarget = {
  __typename?: 'DeepLinkPathTarget';
  path: Scalars['String']['output'];
  type: DeepLinkTargetType;
};

export type DeepLinkPathTargetDeepLinkTaskTarget =
  | DeepLinkPathTarget
  | DeepLinkTaskTarget;

export type DeepLinkResponse = {
  __typename?: 'DeepLinkResponse';
  active: Scalars['Boolean']['output'];
  target?: Maybe<DeepLinkPathTargetDeepLinkTaskTarget>;
  token?: Maybe<Scalars['String']['output']>;
};

export enum DeepLinkTargetType {
  Path = 'PATH',
  Task = 'TASK',
}

export type DeepLinkTaskTarget = {
  __typename?: 'DeepLinkTaskTarget';
  relatedObjectId?: Maybe<Scalars['String']['output']>;
  relatedObjectType?: Maybe<TaskRelatedObject>;
  taskId: Scalars['UUID']['output'];
  type: DeepLinkTargetType;
};

export type DeepLinksHealth = {
  __typename?: 'DeepLinksHealth';
  status: Scalars['String']['output'];
};

export type Dosage = {
  __typename?: 'Dosage';
  additionalInstruction?: Maybe<Array<CodeableConcept>>;
  asNeeded?: Maybe<Scalars['Boolean']['output']>;
  asNeededFor?: Maybe<Array<CodeableConcept>>;
  maxDosePerAdministration?: Maybe<SimpleQuantity>;
  maxDosePerLifetime?: Maybe<SimpleQuantity>;
  maxDosePerPeriod?: Maybe<Array<Ratio>>;
  method?: Maybe<CodeableConcept>;
  patientInstruction?: Maybe<Scalars['String']['output']>;
  route?: Maybe<CodeableConcept>;
  sequence?: Maybe<Scalars['Int']['output']>;
  site?: Maybe<CodeableConcept>;
  text?: Maybe<Scalars['String']['output']>;
  timing?: Maybe<Timing>;
};

export type DosageInput = {
  additionalInstruction?: InputMaybe<Array<CodeableConceptInput>>;
  asNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  asNeededFor?: InputMaybe<Array<CodeableConceptInput>>;
  maxDosePerAdministration?: InputMaybe<SimpleQuantityInput>;
  maxDosePerLifetime?: InputMaybe<SimpleQuantityInput>;
  maxDosePerPeriod?: InputMaybe<Array<RatioInput>>;
  method?: InputMaybe<CodeableConceptInput>;
  patientInstruction?: InputMaybe<Scalars['String']['input']>;
  route?: InputMaybe<CodeableConceptInput>;
  sequence?: InputMaybe<Scalars['Int']['input']>;
  site?: InputMaybe<CodeableConceptInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  timing?: InputMaybe<TimingInput>;
};

export type DuplicateCareJourneyTemplateParams = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Duration = {
  __typename?: 'Duration';
  unit: DurationUnit;
  value: Scalars['Int']['output'];
};

export type DurationInput = {
  unit: DurationUnit;
  value: Scalars['Int']['input'];
};

export enum DurationUnit {
  Days = 'DAYS',
  Hours = 'HOURS',
  Minutes = 'MINUTES',
  Weeks = 'WEEKS',
}

export type Effective = {
  __typename?: 'Effective';
  effectiveDateTime?: Maybe<Scalars['String']['output']>;
  effectiveInstant?: Maybe<Scalars['UTCDateTime']['output']>;
  effectivePeriod?: Maybe<Period>;
  effectiveTiming?: Maybe<Timing>;
};

export type EffectiveInput = {
  effectiveDateTime?: InputMaybe<Scalars['String']['input']>;
  effectiveInstant?: InputMaybe<Scalars['UTCDateTime']['input']>;
  effectivePeriod?: InputMaybe<PeriodInput>;
  effectiveTiming?: InputMaybe<TimingInput>;
};

export type EligibilityCheckInput = {
  birthDate: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  workEmail?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type EligibilityHealth = {
  __typename?: 'EligibilityHealth';
  gitSha: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export enum EligibilityType {
  Enterprise = 'ENTERPRISE',
  Pilot = 'PILOT',
  PreEnterprise = 'PRE_ENTERPRISE',
}

export type EligibilityWithAllowance = {
  __typename?: 'EligibilityWithAllowance';
  ineligibleReason?: Maybe<IneligibleReason>;
  isEligible: Scalars['Boolean']['output'];
};

export type EmailAddress = {
  __typename?: 'EmailAddress';
  use: ContactUse;
  value: Scalars['String']['output'];
};

export type EmailAddressInput = {
  use: ContactUse;
  value: Scalars['String']['input'];
};

export type Employer = {
  __typename?: 'Employer';
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  memberServicesPhoneNumber?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  networkId: Scalars['UUID']['output'];
  nurselinePhoneNumber?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['UUID']['output'];
  thirdPartyAdministrator: ThirdPartyAdministrator;
  thirdPartyAdministratorId: Scalars['UUID']['output'];
  tpaMemberUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['UTCDateTime']['output'];
  wrapNetworkName?: Maybe<Scalars['String']['output']>;
  wrapNetworkUrl?: Maybe<Scalars['String']['output']>;
};

export type EmployerAdditionalBenefits = {
  __typename?: 'EmployerAdditionalBenefits';
  accessCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['UTCDateTime']['output'];
  description: Scalars['String']['output'];
  employerId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  tenantId: Scalars['UUID']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type Encounter = {
  __typename?: 'Encounter';
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['UUID']['output']>;
  clinicalNote: ClinicalNote;
  clinicalNoteType?: Maybe<ClinicalNoteType>;
  createdAt: Scalars['UTCDateTime']['output'];
  createdBy: Scalars['UUID']['output'];
  deletedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  endedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  id: Scalars['UUID']['output'];
  patient: Patient;
  patientId: Scalars['UUID']['output'];
  programId: Scalars['UUID']['output'];
  staff: Staff;
  staffId: Scalars['UUID']['output'];
  startedAt: Scalars['UTCDateTime']['output'];
  status: EncounterStatus;
  summary?: Maybe<EncounterSummary>;
  type: EncounterType;
  updatedAt: Scalars['UTCDateTime']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export enum EncounterStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Noshow = 'NOSHOW',
}

export type EncounterSummary = {
  __typename?: 'EncounterSummary';
  goals?: Maybe<Scalars['String']['output']>;
  nextSteps?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
};

export type EncounterSummaryInput = {
  goals?: InputMaybe<Scalars['String']['input']>;
  nextSteps?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export enum EncounterType {
  Message = 'MESSAGE',
  Video = 'VIDEO',
}

export type Enrollment = {
  __typename?: 'Enrollment';
  createdAt: Scalars['UTCDateTime']['output'];
  enrolledAt?: Maybe<Scalars['UTCDateTime']['output']>;
  enrolledUntil?: Maybe<Scalars['UTCDateTime']['output']>;
  enrollmentStatus: EnrollmentStatus;
  id: Scalars['ID']['output'];
  payer?: Maybe<Payer>;
  program: Program;
  programId: Scalars['UUID']['output'];
  unenrolledAt?: Maybe<Scalars['UTCDateTime']['output']>;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type EnrollmentFilters = {
  organizationEmployeeId?: InputMaybe<Scalars['UUID']['input']>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<EnrollmentStatus>;
};

export enum EnrollmentStatus {
  Enrolled = 'ENROLLED',
  NeverEnrolled = 'NEVER_ENROLLED',
  PreviouslyEnrolled = 'PREVIOUSLY_ENROLLED',
}

export enum ExpectationHint {
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  PhoneNumber = 'PHONE_NUMBER',
}

export enum ExpectationType {
  Date = 'DATE',
  Number = 'NUMBER',
  Text = 'TEXT',
}

export type FacilitiesNearList = {
  __typename?: 'FacilitiesNearList';
  items: Array<FacilityNearResult>;
  totalCount: Scalars['Int']['output'];
};

export type Facility = {
  __typename?: 'Facility';
  accessible: Scalars['Boolean']['output'];
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  archivedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  city: Scalars['String']['output'];
  createdAt: Scalars['UTCDateTime']['output'];
  geoLocation: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  state: Scalars['String']['output'];
  tenantId: Scalars['UUID']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['UTCDateTime']['output'];
  zipCode: Scalars['String']['output'];
};

export type FacilityAddress = {
  __typename?: 'FacilityAddress';
  accessible: Scalars['Boolean']['output'];
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  geoLocation: Scalars['String']['output'];
  state: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type FacilityNearResult = {
  __typename?: 'FacilityNearResult';
  address: FacilityAddress;
  distance: Scalars['Float']['output'];
  facility: Facility;
};

export type FilterCareJourneyParams = {
  careJourneyTemplateType?: InputMaybe<CareJourneyType>;
  careJourneyTemplateVersionId?: InputMaybe<Scalars['UUID']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  offboarded?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  previousCareJourneyId?: InputMaybe<Scalars['UUID']['input']>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
  retired?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<CareJourneyStatus>;
};

export type FilterNearFacilitiesParams = {
  boundingBox?: InputMaybe<BoundingBoxInput>;
  facilityType?: InputMaybe<Scalars['String']['input']>;
  geoLocation?: InputMaybe<GeoLocationInput>;
  maxDistance?: Scalars['Int']['input'];
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type FilterNearPractitionerParams = {
  boundingBox?: InputMaybe<BoundingBoxInput>;
  combineAddresses?: Scalars['Boolean']['input'];
  geoLocation?: InputMaybe<GeoLocationInput>;
  maxDistance?: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  specialtyId?: InputMaybe<Scalars['UUID']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type FilterPatientParams = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  includeDeactivated?: Scalars['Boolean']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobilePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  nameSearch?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type FilterPractitionerNetworkParams = {
  networkId?: InputMaybe<Scalars['UUID']['input']>;
  practitionerId?: InputMaybe<Scalars['UUID']['input']>;
};

export type FilterPractitionerSpecialtyParams = {
  practitionerId?: InputMaybe<Scalars['UUID']['input']>;
  specialtyId?: InputMaybe<Scalars['UUID']['input']>;
};

export type FilterSequentialTouchpointParams = {
  careJourneyId?: InputMaybe<Scalars['UUID']['input']>;
  careJourneyStatus?: InputMaybe<CareJourneyStatus>;
  category?: InputMaybe<Array<TouchpointCategory>>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  excludeLockedWithRules?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  sequentialTouchpointTemplateId?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<TouchpointStatus>;
  statusRange?: InputMaybe<Array<TouchpointStatus>>;
};

export type FilterSequentialTouchpointTemplateParams = {
  ancestryId?: InputMaybe<Scalars['UUID']['input']>;
  anchor?: InputMaybe<AnchorType>;
  anchorOffset?: InputMaybe<Scalars['Int']['input']>;
  anchorTouchpointTemplateId?: InputMaybe<Scalars['UUID']['input']>;
  careJourneyTemplateVersionId?: InputMaybe<Scalars['UUID']['input']>;
  category?: InputMaybe<TouchpointCategory>;
  hasTaskFrequency?: InputMaybe<Scalars['Boolean']['input']>;
  hasTouchpointFrequency?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  includeArchived?: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  openUponEnrollment?: InputMaybe<Scalars['Boolean']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  reopenOnEventDateUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  sourceId?: InputMaybe<Scalars['UUID']['input']>;
  taskTemplateId?: InputMaybe<Scalars['UUID']['input']>;
  type?: InputMaybe<TouchpointType>;
  updateType?: InputMaybe<TouchpointTemplateUpdateType>;
};

export type FilterTaskParams = {
  assigneeId?: InputMaybe<Scalars['UUID']['input']>;
  category?: InputMaybe<TaskCategory>;
  name?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  priority?: InputMaybe<TaskPriority>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
  relatedObjectId?: InputMaybe<Scalars['String']['input']>;
  relatedObjectType?: InputMaybe<TaskRelatedObject>;
  reporterId?: InputMaybe<Scalars['UUID']['input']>;
  role?: InputMaybe<StaffRole>;
  status?: InputMaybe<Array<TaskStatus>>;
  taskExpireAtLt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  taskIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  taskType?: InputMaybe<TaskType>;
};

export type FilterTaskTemplateParams = {
  category?: InputMaybe<TaskCategory>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<TaskPriority>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
  role?: InputMaybe<StaffRole>;
  slug?: InputMaybe<Scalars['String']['input']>;
  taskType?: InputMaybe<TaskType>;
};

export type Form = {
  __typename?: 'Form';
  archivedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  createdAt: Scalars['UTCDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  draftVersion: FormVersion;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  programId: Scalars['UUID']['output'];
  publishedVersion?: Maybe<FormVersion>;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type FormDestination = {
  __typename?: 'FormDestination';
  components?: Maybe<Array<FormDestinationItem>>;
  dataConfig: Scalars['JSON']['output'];
  description?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type FormDestinationFilters = {
  dataType?: InputMaybe<Array<DataType>>;
  programId?: InputMaybe<Scalars['String']['input']>;
};

export type FormDestinationItem = {
  __typename?: 'FormDestinationItem';
  calculationConfig?: Maybe<Scalars['JSON']['output']>;
  dataConfig: Scalars['JSON']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type FormDestinationSearchResult = {
  __typename?: 'FormDestinationSearchResult';
  components?: Maybe<Array<FormDestinationItem>>;
  dataConfig: Scalars['JSON']['output'];
  path: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type FormFilters = {
  includeArchived?: Scalars['Boolean']['input'];
  programId?: InputMaybe<Scalars['UUID']['input']>;
};

export type FormResponse = {
  __typename?: 'FormResponse';
  createdAt: Scalars['UTCDateTime']['output'];
  formVersion: FormVersion;
  formVersionId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  interpretedResponses?: Maybe<InterpretedResponses>;
  pageNumber?: Maybe<Scalars['Int']['output']>;
  patientId: Scalars['UUID']['output'];
  programId: Scalars['UUID']['output'];
  response?: Maybe<Scalars['JSON']['output']>;
  submittedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  task?: Maybe<Task>;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type FormResponseAppointmentContentView =
  | Appointment
  | ContentView
  | FormResponse;

export type FormResponseFilters = {
  formId?: InputMaybe<Scalars['UUID']['input']>;
  isSubmitted?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
};

export type FormVersion = {
  __typename?: 'FormVersion';
  config: Scalars['JSON']['output'];
  createdAt: Scalars['UTCDateTime']['output'];
  form: Form;
  id: Scalars['UUID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  programId: Scalars['UUID']['output'];
  publishedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type FormsHealth = {
  __typename?: 'FormsHealth';
  status: Scalars['String']['output'];
};

export type Frequency = {
  __typename?: 'Frequency';
  duration: FrequencyDuration;
  value: Scalars['Int']['output'];
};

export enum FrequencyDuration {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
}

export type FrequencyInput = {
  duration: FrequencyDuration;
  value: Scalars['Int']['input'];
};

export type GenderIdentity = {
  __typename?: 'GenderIdentity';
  text: Scalars['String']['output'];
  value: GenderIdentityCode;
};

export enum GenderIdentityCode {
  Man = 'MAN',
  NonBinary = 'NON_BINARY',
  Other = 'OTHER',
  TransgenderMan = 'TRANSGENDER_MAN',
  TransgenderWoman = 'TRANSGENDER_WOMAN',
  Woman = 'WOMAN',
}

export type GenderIdentityInput = {
  text?: InputMaybe<Scalars['String']['input']>;
  value: GenderIdentityCode;
};

export type GeoLocationInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type HealthAlertsHealth = {
  __typename?: 'HealthAlertsHealth';
  status: Scalars['String']['output'];
};

export type HealthCoachNote = {
  __typename?: 'HealthCoachNote';
  asyncNote?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<HealthCoachPlanNote>;
  subjectiveObjective?: Maybe<HealthCoachSubjectiveObjectiveNote>;
};

export type HealthCoachNoteInput = {
  asyncNote?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<HealthCoachPlanNoteInput>;
  subjectiveObjective?: InputMaybe<HealthCoachSubjectiveObjectiveNoteInput>;
};

export type HealthCoachPlanNote = {
  __typename?: 'HealthCoachPlanNote';
  postVisitSummary?: Maybe<Scalars['String']['output']>;
  sessionNumber?: Maybe<Scalars['String']['output']>;
};

export type HealthCoachPlanNoteInput = {
  postVisitSummary?: InputMaybe<Scalars['String']['input']>;
  sessionNumber?: InputMaybe<Scalars['String']['input']>;
};

export type HealthCoachSubjectiveObjectiveNote = {
  __typename?: 'HealthCoachSubjectiveObjectiveNote';
  generalSessionNotes?: Maybe<Scalars['String']['output']>;
  healthCoachNotes?: Maybe<Scalars['String']['output']>;
  preSessionNotes?: Maybe<Scalars['String']['output']>;
};

export type HealthCoachSubjectiveObjectiveNoteInput = {
  generalSessionNotes?: InputMaybe<Scalars['String']['input']>;
  healthCoachNotes?: InputMaybe<Scalars['String']['input']>;
  preSessionNotes?: InputMaybe<Scalars['String']['input']>;
};

export type HealthDataHealth = {
  __typename?: 'HealthDataHealth';
  status: Scalars['String']['output'];
};

export type Icd10AutocompleteResult = {
  __typename?: 'ICD10AutocompleteResult';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Icd10Code = {
  __typename?: 'ICD10Code';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Icd10CodeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Identifier = {
  __typename?: 'Identifier';
  period?: Maybe<Period>;
  system?: Maybe<Scalars['String']['output']>;
  type?: Maybe<IdentifierType>;
  use?: Maybe<IdentifierUse>;
  value?: Maybe<Scalars['String']['output']>;
};

export type IdentifierInput = {
  period?: InputMaybe<PeriodInput>;
  system?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<IdentifierType>;
  use?: InputMaybe<IdentifierUse>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum IdentifierType {
  Acsn = 'ACSN',
  Brn = 'BRN',
  Dl = 'DL',
  Dr = 'DR',
  En = 'EN',
  Fill = 'FILL',
  Jhn = 'JHN',
  Mcn = 'MCN',
  Md = 'MD',
  Mr = 'MR',
  Niip = 'NIIP',
  Plac = 'PLAC',
  Ppn = 'PPN',
  Prn = 'PRN',
  Sb = 'SB',
  Sno = 'SNO',
  Tax = 'TAX',
  Udi = 'UDI',
}

export enum IdentifierUse {
  Official = 'OFFICIAL',
  Old = 'OLD',
  Secondary = 'SECONDARY',
  Temp = 'TEMP',
  Usual = 'USUAL',
}

export enum IneligibleReason {
  BenefitAllowanceExhausted = 'BENEFIT_ALLOWANCE_EXHAUSTED',
  InactiveEmploymentStatus = 'INACTIVE_EMPLOYMENT_STATUS',
  InactiveOrganizationProgramStatus = 'INACTIVE_ORGANIZATION_PROGRAM_STATUS',
  InvalidOrganizationProgramCombination = 'INVALID_ORGANIZATION_PROGRAM_COMBINATION',
  MultipleRecordsFound = 'MULTIPLE_RECORDS_FOUND',
  NoRecordFound = 'NO_RECORD_FOUND',
  OrganizationNotFound = 'ORGANIZATION_NOT_FOUND',
  ProgramNotFound = 'PROGRAM_NOT_FOUND',
}

export type InferenceResult = {
  __typename?: 'InferenceResult';
  intents?: Maybe<Array<Intent>>;
};

export type Intent = {
  __typename?: 'Intent';
  confidence: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type InterpretedResponses = {
  __typename?: 'InterpretedResponses';
  allValid: Scalars['Boolean']['output'];
  questions: Array<QuestionAndResponse>;
};

export type LegacyNotificationReminderConfig = {
  __typename?: 'LegacyNotificationReminderConfig';
  conditions: Array<TouchpointNotificationReminderCondition>;
};

export type LegacyNotificationReminderConfigInput = {
  conditions: Array<TouchpointNotificationReminderConditionInput>;
};

export type ListAlertFilters = {
  acknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  careJourneyId?: InputMaybe<Scalars['UUID']['input']>;
  monitorId?: InputMaybe<Scalars['UUID']['input']>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  priorities?: InputMaybe<Array<MonitorPriority>>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
};

export type ListAppointmentTypesInput = {
  programId?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<StaffRole>>;
};

export type ListAppointmentsInput = {
  endTime_Gte?: InputMaybe<Scalars['UTCDateTime']['input']>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
  staffId?: InputMaybe<Scalars['UUID']['input']>;
  startTime_Gte?: InputMaybe<Scalars['UTCDateTime']['input']>;
  status?: InputMaybe<Array<AppointmentStatus>>;
};

export type ListContentViewFilters = {
  contentId?: InputMaybe<Scalars['String']['input']>;
  contentType?: InputMaybe<ContentfulContentType>;
  dedupe?: InputMaybe<Scalars['Boolean']['input']>;
  parentContentId?: InputMaybe<Scalars['String']['input']>;
  parentContentType?: InputMaybe<ContentfulContentType>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
  taskId?: InputMaybe<Scalars['UUID']['input']>;
};

export type ListEncounterFilters = {
  appointmentId?: InputMaybe<Scalars['UUID']['input']>;
  clinicalNoteType?: InputMaybe<ClinicalNoteType>;
  endedAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
  staffId?: InputMaybe<Scalars['UUID']['input']>;
  startedAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  status?: InputMaybe<EncounterStatus>;
  type?: InputMaybe<EncounterType>;
};

export type ListMonitorFilters = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  audiences?: InputMaybe<Array<MonitorAudience>>;
  careJourneyId?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  priorities?: InputMaybe<Array<MonitorPriority>>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
  references?: InputMaybe<Array<RuleReference>>;
  scope?: InputMaybe<MonitorScope>;
  type?: InputMaybe<MonitorType>;
};

export type ListStaffsInput = {
  licenseState?: InputMaybe<StateCode>;
  licenseStatus?: InputMaybe<ProfessionalLicenseStatus>;
  role?: InputMaybe<StaffRole>;
  roles?: InputMaybe<Array<StaffRole>>;
};

export type MedicationAutocompleteResult = {
  __typename?: 'MedicationAutocompleteResult';
  names: Array<Scalars['String']['output']>;
};

export type MedicationLookupResult = {
  __typename?: 'MedicationLookupResult';
  displayName: Scalars['String']['output'];
  rxcui: Scalars['Int']['output'];
  strength: Scalars['String']['output'];
};

export type MedicationStatement = {
  __typename?: 'MedicationStatement';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  patientId: Scalars['UUID']['output'];
  programId: Scalars['UUID']['output'];
  resource: MedicationStatementResource;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type MedicationStatementFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
};

export type MedicationStatementResource = {
  __typename?: 'MedicationStatementResource';
  adherence?: Maybe<Adherence>;
  category?: Maybe<Array<CodeableConcept>>;
  dateAsserted?: Maybe<Scalars['String']['output']>;
  dosage?: Maybe<Array<Dosage>>;
  effective?: Maybe<Effective>;
  identifier?: Maybe<Array<Identifier>>;
  medication: CodeableReference;
  note?: Maybe<Array<Annotation>>;
  reason?: Maybe<CodeableReference>;
  status: MedicationStatementStatus;
};

export type MedicationStatementResourceInput = {
  adherence?: InputMaybe<AdherenceInput>;
  category?: InputMaybe<Array<CodeableConceptInput>>;
  dateAsserted?: InputMaybe<Scalars['String']['input']>;
  dosage?: InputMaybe<Array<DosageInput>>;
  effective?: InputMaybe<EffectiveInput>;
  identifier?: InputMaybe<Array<IdentifierInput>>;
  medication: CodeableReferenceInput;
  note?: InputMaybe<Array<AnnotationInput>>;
  reason?: InputMaybe<CodeableReferenceInput>;
  status: MedicationStatementStatus;
};

export enum MedicationStatementStatus {
  Draft = 'DRAFT',
  EnteredInError = 'ENTERED_IN_ERROR',
  Recorded = 'RECORDED',
}

export type Member = {
  __typename?: 'Member';
  additionalBenefits: Array<EmployerAdditionalBenefits>;
  businessUnit?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['UTCDateTime']['output'];
  dateOfBirth: Scalars['Date']['output'];
  effectiveFrom?: Maybe<Scalars['Date']['output']>;
  effectiveTo?: Maybe<Scalars['Date']['output']>;
  eligiblePrograms: Array<Program>;
  employer: Employer;
  employerId: Scalars['UUID']['output'];
  firstName: Scalars['String']['output'];
  homePostalCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  nurseLineEligible: Scalars['Boolean']['output'];
  patientId?: Maybe<Scalars['UUID']['output']>;
  tenantId: Scalars['UUID']['output'];
  tpaMemberId: Scalars['String']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export enum MessageSortField {
  CreatedAt = 'CREATED_AT',
}

export type MessagingHealth = {
  __typename?: 'MessagingHealth';
  status: Scalars['String']['output'];
};

export enum Modality {
  Agent = 'AGENT',
  Web = 'WEB',
}

export type ModifyStaffProfessionalLicenseInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['Date']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  licenseState?: InputMaybe<StateCode>;
  operation: ModifyStaffProfessionalLicenseOperation;
  professionalLicenseId?: InputMaybe<Scalars['UUID']['input']>;
  type?: InputMaybe<ProfessionalLicenseType>;
};

export enum ModifyStaffProfessionalLicenseOperation {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE',
}

export type Monitor = {
  __typename?: 'Monitor';
  archivedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  audience: Array<MonitorAudience>;
  careJourneyId?: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  muted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  patientId?: Maybe<Scalars['UUID']['output']>;
  patientMessageTemplate: Scalars['String']['output'];
  priority: MonitorPriority;
  programId?: Maybe<Scalars['UUID']['output']>;
  rules: SimpleConditionCompoundCondition;
  scope: MonitorScope;
  staffClinicalMessageTemplate: Scalars['String']['output'];
  staffNonclinicalMessageTemplate: Scalars['String']['output'];
  tenantId: Scalars['UUID']['output'];
  type: MonitorType;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export enum MonitorAudience {
  Patient = 'PATIENT',
  StaffClinical = 'STAFF_CLINICAL',
  StaffNonclinical = 'STAFF_NONCLINICAL',
}

export type MonitorList = {
  __typename?: 'MonitorList';
  items: Array<Monitor>;
  totalCount: Scalars['Int']['output'];
};

export enum MonitorPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

export enum MonitorScope {
  CareJourney = 'CARE_JOURNEY',
  Patient = 'PATIENT',
  Program = 'PROGRAM',
  Tenant = 'TENANT',
}

export enum MonitorSortField {
  ArchivedAt = 'ARCHIVED_AT',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Priority = 'PRIORITY',
}

export enum MonitorType {
  Event = 'EVENT',
}

export type Mutation = {
  __typename?: 'Mutation';
  acknowledgeAlert: Alert;
  archiveCareJourneyTemplate: CareJourneyTemplate;
  archiveFacility: Facility;
  archiveForm: Form;
  archiveMonitor: Monitor;
  archivePractitioner: Practitioner;
  archivePractitionerAddress: PractitionerAddress;
  backfillPatient: Patient;
  batchCreateObservations: Array<Observation>;
  cancelAppointment: Appointment;
  cancelEncounter: Encounter;
  completeEncounter: Encounter;
  createAgentConversation: AgentConversation;
  createAgentMessage: AgentMessage;
  createAllergyIntolerance: AllergyIntolerance;
  createAppointment: Appointment;
  createCareJourney: CreateCareJourney;
  createCareJourneyTemplate: CareJourneyTemplate;
  createCondition: Condition;
  createContentView: ContentView;
  createConversationAIMessage: BotResponseOutput;
  createCronofyElementToken: CronofyElementToken;
  createCustomObservationDefinition: ObservationDefinition;
  createDeepLink: Scalars['String']['output'];
  createEncounter: Encounter;
  createEnrollment: Enrollment;
  createFacility: Facility;
  createForm: Form;
  createMedicationStatement: MedicationStatement;
  createMonitor: Monitor;
  createNetwork: Network;
  createObservation: Observation;
  createObservationDefinition: ObservationDefinition;
  createOrganization: Organization;
  createOrganizationEmployee: OrganizationEmployee;
  createOrganizationProgram: OrganizationProgram;
  createPatient: Patient;
  createPractitioner: Practitioner;
  createPractitionerAddress: PractitionerAddress;
  createPractitionerNetwork: PractitionerNetwork;
  createPractitionerSpecialty: PractitionerSpecialty;
  createProfessionalLicense: ProfessionalLicense;
  createProgram: Program;
  createSequentialTouchpoint: UpdatedSequentialTouchpoint;
  createSequentialTouchpointTemplate: SequentialTouchpointTemplate;
  createStaff: Staff;
  createStaffProgram: StaffProgram;
  createTask: Task;
  createTaskTemplate: TaskTemplate;
  createTenant: Tenant;
  createWebAgentMessage: AgentMessage;
  deleteAgentConversation: AgentConversation;
  deleteAgentMessage: AgentMessage;
  deleteEncounter: Encounter;
  deleteObservation: Observation;
  deleteSequentialTouchpointTemplate: SequentialTouchpointTemplate;
  duplicateCareJourneyTemplate: CareJourneyTemplate;
  duplicateForm: Form;
  linkStaffAccount: BasicStaff;
  markTaskComplete: Task;
  publishCareJourneyTemplate: CareJourneyTemplate;
  publishForm: Form;
  recreateDeepLink?: Maybe<Scalars['Void']['output']>;
  /** @deprecated Reset password will be handled by Cognito */
  resetUserPassword?: Maybe<Scalars['Void']['output']>;
  /** @deprecated Email verification will be handled by Cognito */
  sendPatientEmailVerification: SendVerificationEmailOutput;
  supercedeObservation: Observation;
  triggerNotification?: Maybe<Scalars['Void']['output']>;
  unenroll: Enrollment;
  updateAgentConversationStatus: AgentConversation;
  updateAllergyIntolerance: AllergyIntolerance;
  updateAppointment: Appointment;
  updateAppointmentStatus: Appointment;
  updateCareJourney: UpdateCareJourney;
  updateCareJourneyTemplate: CareJourneyTemplate;
  updateCondition: Condition;
  updateEncounter: Encounter;
  updateEnrollment: Enrollment;
  updateForm: Form;
  updateMedicationStatement: MedicationStatement;
  updateMonitor: Monitor;
  updateOrganization: Organization;
  updateOrganizationEmployee: OrganizationEmployee;
  updateOrganizationProgram: OrganizationProgram;
  updatePatient: Patient;
  updatePractitioner: Practitioner;
  updateProfessionalLicense: ProfessionalLicense;
  updateProgram: Program;
  updateSequentialTouchpoint: UpdatedSequentialTouchpoint;
  updateSequentialTouchpointTemplate: SequentialTouchpointTemplate;
  updateStaff: Staff;
  updateStaffProgram: StaffProgram;
  updateTask: Task;
  updateTaskTemplate: TaskTemplate;
  updateTenant: Tenant;
  upsertCronofyProfile: CronofyProfile;
  upsertFormResponseByTaskId: FormResponse;
};

export type MutationAcknowledgeAlertArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationArchiveCareJourneyTemplateArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationArchiveFacilityArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationArchiveFormArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationArchiveMonitorArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationArchivePractitionerArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationArchivePractitionerAddressArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationBackfillPatientArgs = {
  input: BackfillPatientInput;
};

export type MutationBatchCreateObservationsArgs = {
  input: BatchCreateValuesInput;
};

export type MutationCancelAppointmentArgs = {
  id: Scalars['UUID']['input'];
  input?: InputMaybe<CancelAppointmentInput>;
};

export type MutationCancelEncounterArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationCompleteEncounterArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationCreateAgentConversationArgs = {
  input: CreateAgentConversationInput;
};

export type MutationCreateAgentMessageArgs = {
  input: CreateAgentMessageInput;
};

export type MutationCreateAllergyIntoleranceArgs = {
  input: CreateAllergyIntoleranceInput;
};

export type MutationCreateAppointmentArgs = {
  input: CreateAppointmentInput;
};

export type MutationCreateCareJourneyArgs = {
  input: CreateCareJourneyInput;
};

export type MutationCreateCareJourneyTemplateArgs = {
  input: CreateCareJourneyTemplateInput;
};

export type MutationCreateConditionArgs = {
  input: CreateConditionInput;
};

export type MutationCreateContentViewArgs = {
  input: CreateContentViewInput;
};

export type MutationCreateConversationAiMessageArgs = {
  input: CreateConversationAiMessageInput;
};

export type MutationCreateCronofyElementTokenArgs = {
  input: CreateCronofyElementTokenInput;
};

export type MutationCreateCustomObservationDefinitionArgs = {
  input: CreateCustomObservationDefinitionInput;
};

export type MutationCreateDeepLinkArgs = {
  input: CreateDeepLinkInput;
};

export type MutationCreateEncounterArgs = {
  input: CreateEncounterInput;
};

export type MutationCreateEnrollmentArgs = {
  input: CreateEnrollmentInput;
};

export type MutationCreateFacilityArgs = {
  createFacilityParams: CreateFacilityParams;
};

export type MutationCreateFormArgs = {
  input: CreateFormInput;
};

export type MutationCreateMedicationStatementArgs = {
  input: CreateMedicationStatementInput;
};

export type MutationCreateMonitorArgs = {
  input: CreateMonitorInput;
};

export type MutationCreateNetworkArgs = {
  name: Scalars['String']['input'];
};

export type MutationCreateObservationArgs = {
  input: CreateObservationInput;
};

export type MutationCreateObservationDefinitionArgs = {
  input: CreateObservationDefinitionInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganization;
};

export type MutationCreateOrganizationEmployeeArgs = {
  input: CreateOrganizationEmployee;
};

export type MutationCreateOrganizationProgramArgs = {
  input: CreateOrganizationProgram;
};

export type MutationCreatePatientArgs = {
  input: CreatePatientInput;
};

export type MutationCreatePractitionerArgs = {
  createPractitionerParams: CreatePractitionerParams;
};

export type MutationCreatePractitionerAddressArgs = {
  createPractitionerAddressParams: CreatePractitionerAddressParams;
};

export type MutationCreatePractitionerNetworkArgs = {
  input: CreatePractitionerNetworkParams;
};

export type MutationCreatePractitionerSpecialtyArgs = {
  input: CreatePractitionerSpecialtyParams;
};

export type MutationCreateProfessionalLicenseArgs = {
  input: CreateProfessionalLicenseInput;
};

export type MutationCreateProgramArgs = {
  input: CreateProgramInput;
};

export type MutationCreateSequentialTouchpointArgs = {
  input: CreateSequentialTouchpointInput;
};

export type MutationCreateSequentialTouchpointTemplateArgs = {
  input: CreateSequentialTouchpointTemplateInput;
};

export type MutationCreateStaffArgs = {
  input: CreateStaffInput;
};

export type MutationCreateStaffProgramArgs = {
  input: CreateStaffProgramInput;
};

export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};

export type MutationCreateTaskTemplateArgs = {
  input: CreateTaskTemplateInput;
};

export type MutationCreateTenantArgs = {
  input: CreateTenantInput;
};

export type MutationCreateWebAgentMessageArgs = {
  input: CreateWebAgentMessageInput;
};

export type MutationDeleteAgentConversationArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationDeleteAgentMessageArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationDeleteEncounterArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationDeleteObservationArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationDeleteSequentialTouchpointTemplateArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationDuplicateCareJourneyTemplateArgs = {
  id: Scalars['UUID']['input'];
  input?: InputMaybe<DuplicateCareJourneyTemplateParams>;
};

export type MutationDuplicateFormArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationMarkTaskCompleteArgs = {
  taskId: Scalars['UUID']['input'];
};

export type MutationPublishCareJourneyTemplateArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationPublishFormArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationRecreateDeepLinkArgs = {
  input: ReCreateDeepLinkInput;
};

export type MutationResetUserPasswordArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationSendPatientEmailVerificationArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationSupercedeObservationArgs = {
  id: Scalars['UUID']['input'];
  input: CreateObservationInput;
};

export type MutationTriggerNotificationArgs = {
  input: TriggerNotificationInput;
};

export type MutationUnenrollArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationUpdateAgentConversationStatusArgs = {
  id: Scalars['UUID']['input'];
  status: AgentConversationStatus;
};

export type MutationUpdateAllergyIntoleranceArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateAllergyIntoleranceInput;
};

export type MutationUpdateAppointmentArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateAppointmentInput;
};

export type MutationUpdateAppointmentStatusArgs = {
  id: Scalars['UUID']['input'];
  status: AppointmentStatus;
};

export type MutationUpdateCareJourneyArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateCareJourneyInput;
};

export type MutationUpdateCareJourneyTemplateArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateCareJourneyTemplateInput;
};

export type MutationUpdateConditionArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateConditionInput;
};

export type MutationUpdateEncounterArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateEncounterInput;
};

export type MutationUpdateEnrollmentArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateEnrollmentInput;
};

export type MutationUpdateFormArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateFormInput;
};

export type MutationUpdateMedicationStatementArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateMedicationStatementInput;
};

export type MutationUpdateMonitorArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateMonitorInput;
};

export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganization;
  organizationId: Scalars['UUID']['input'];
};

export type MutationUpdateOrganizationEmployeeArgs = {
  input: UpdateOrganizationEmployee;
  organizationEmployeeId: Scalars['UUID']['input'];
};

export type MutationUpdateOrganizationProgramArgs = {
  input: UpdateOrganizationProgram;
  organizationProgramId: Scalars['UUID']['input'];
};

export type MutationUpdatePatientArgs = {
  id: Scalars['UUID']['input'];
  input: UpdatePatientInput;
};

export type MutationUpdatePractitionerArgs = {
  id: Scalars['UUID']['input'];
  updatePractitionerParams: UpdatePractitionerParams;
};

export type MutationUpdateProfessionalLicenseArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateProfessionalLicenseInput;
};

export type MutationUpdateProgramArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateProgramInput;
};

export type MutationUpdateSequentialTouchpointArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateSequentialTouchpointInput;
};

export type MutationUpdateSequentialTouchpointTemplateArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateSequentialTouchpointTemplateInput;
};

export type MutationUpdateStaffArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateStaffInput;
};

export type MutationUpdateStaffProgramArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateStaffProgramInput;
};

export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
  taskId: Scalars['UUID']['input'];
};

export type MutationUpdateTaskTemplateArgs = {
  input: UpdateTaskTemplateInput;
  taskTemplateId: Scalars['UUID']['input'];
};

export type MutationUpdateTenantArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateTenantInput;
};

export type MutationUpsertCronofyProfileArgs = {
  input: UpsertCronofyProfileInput;
};

export type MutationUpsertFormResponseByTaskIdArgs = {
  input: UpsertFormResponseInput;
  taskId: Scalars['UUID']['input'];
};

export type Network = {
  __typename?: 'Network';
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  practitionersInNetwork: Array<PractitionerNetwork>;
  tenantId: Scalars['UUID']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type NotificationConfig = {
  __typename?: 'NotificationConfig';
  delay?: Maybe<Duration>;
  plainTextBody: Scalars['String']['output'];
};

export type NotificationConfigInput = {
  delay?: InputMaybe<DurationInput>;
  plainTextBody: Scalars['String']['input'];
};

export type NotificationHealth = {
  __typename?: 'NotificationHealth';
  status: Scalars['String']['output'];
};

export type NotificationReminder = {
  __typename?: 'NotificationReminder';
  attributes?: Maybe<Scalars['JSON']['output']>;
  eventName: Scalars['String']['output'];
  scheduledAt: Scalars['UTCDateTime']['output'];
  sequentialOrder: Scalars['Int']['output'];
};

export type NotificationReminderCondition = {
  __typename?: 'NotificationReminderCondition';
  attributes?: Maybe<Scalars['JSON']['output']>;
  eventName: Scalars['String']['output'];
  scheduledAt: Scalars['UTCDateTime']['output'];
  sequentialOrder: Scalars['Int']['output'];
};

export type NotificationReminderConditionInput = {
  attributes?: InputMaybe<Scalars['JSON']['input']>;
  eventName: Scalars['String']['input'];
  field: Scalars['String']['input'];
  sequentialOrder: Scalars['Int']['input'];
  timeDelay: Scalars['Int']['input'];
};

export type NotificationReminderConfig = {
  __typename?: 'NotificationReminderConfig';
  conditions: Array<NotificationReminderCondition>;
};

export type NotificationReminderConfigInput = {
  conditions: Array<NotificationReminderConditionInput>;
};

export type NotificationReminderInput = {
  attributes?: InputMaybe<Scalars['JSON']['input']>;
  eventName: Scalars['String']['input'];
  scheduledAt: Scalars['UTCDateTime']['input'];
  sequentialOrder: Scalars['Int']['input'];
};

export type NotificationSchedule = {
  __typename?: 'NotificationSchedule';
  schedules: Array<NotificationReminder>;
};

export type NotificationScheduleInput = {
  schedules: Array<NotificationReminderInput>;
};

export type Observation = {
  __typename?: 'Observation';
  createdAt: Scalars['UTCDateTime']['output'];
  deletedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  id: Scalars['UUID']['output'];
  observationDefinition?: Maybe<ObservationDefinition>;
  patientId: Scalars['UUID']['output'];
  programId: Scalars['UUID']['output'];
  resource: ObservationResource;
  supercededBy?: Maybe<Scalars['UUID']['output']>;
  tenantId: Scalars['UUID']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type ObservationComponent = {
  __typename?: 'ObservationComponent';
  code: CodeableConcept;
  dataAbsentReason?: Maybe<CodeableConcept>;
  interpretation?: Maybe<CodeableConcept>;
  referenceRange?: Maybe<Array<ReferenceRange>>;
  value?: Maybe<Value>;
};

export type ObservationComponentInput = {
  code: CodeableConceptInput;
  dataAbsentReason?: InputMaybe<CodeableConceptInput>;
  interpretation?: InputMaybe<CodeableConceptInput>;
  referenceRange?: InputMaybe<Array<ReferenceRangeInput>>;
  value?: InputMaybe<ValueInput>;
};

export type ObservationDefinition = {
  __typename?: 'ObservationDefinition';
  archivedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  programId?: Maybe<Scalars['UUID']['output']>;
  resource: ObservationDefinitionResource;
  slug: Scalars['String']['output'];
  tenantId?: Maybe<Scalars['UUID']['output']>;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type ObservationDefinitionAutocompleteResult = {
  __typename?: 'ObservationDefinitionAutocompleteResult';
  id: Scalars['UUID']['output'];
  resource: ObservationDefinitionResource;
  slug: Scalars['String']['output'];
};

export type ObservationDefinitionFilters = {
  dataType?: InputMaybe<Array<DataType>>;
  includeArchived?: Scalars['Boolean']['input'];
  includeGlobal?: Scalars['Boolean']['input'];
  programId?: InputMaybe<Scalars['UUID']['input']>;
  slug?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ObservationDefinitionItem = {
  __typename?: 'ObservationDefinitionItem';
  calculationConfig?: Maybe<Scalars['JSON']['output']>;
  code: CodeableConcept;
  dataConfig: Scalars['JSON']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ObservationDefinitionItemInput = {
  calculationConfig?: InputMaybe<Scalars['JSON']['input']>;
  code: CodeableConceptInput;
  dataConfig: Scalars['JSON']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type ObservationDefinitionResource = {
  __typename?: 'ObservationDefinitionResource';
  code: CodeableConcept;
  components?: Maybe<Array<ObservationDefinitionItem>>;
  dataConfig: Scalars['JSON']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ObservationDefinitionResourceInput = {
  code: CodeableConceptInput;
  components?: InputMaybe<Array<ObservationDefinitionItemInput>>;
  dataConfig: Scalars['JSON']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type ObservationFilters = {
  coding?: InputMaybe<Array<CodingInput>>;
  observationDefinitionId?: InputMaybe<Array<Scalars['UUID']['input']>>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type ObservationResource = {
  __typename?: 'ObservationResource';
  bodySite?: Maybe<CodeableConcept>;
  bodyStructure?: Maybe<Reference>;
  category?: Maybe<CodeableConcept>;
  code?: Maybe<CodeableConcept>;
  component?: Maybe<Array<ObservationComponent>>;
  dataAbsentReason?: Maybe<CodeableConcept>;
  device?: Maybe<Reference>;
  effective?: Maybe<Effective>;
  identifier?: Maybe<Array<Identifier>>;
  interpretation?: Maybe<CodeableConcept>;
  issued?: Maybe<Scalars['UTCDateTime']['output']>;
  method?: Maybe<CodeableConcept>;
  note?: Maybe<Array<Annotation>>;
  performer?: Maybe<Reference>;
  referenceRange?: Maybe<Array<ReferenceRange>>;
  specimen?: Maybe<Reference>;
  status: ObservationStatus;
  subject?: Maybe<Reference>;
  value?: Maybe<Value>;
};

export type ObservationResourceInput = {
  bodySite?: InputMaybe<CodeableConceptInput>;
  bodyStructure?: InputMaybe<ReferenceInput>;
  category?: InputMaybe<CodeableConceptInput>;
  code?: InputMaybe<CodeableConceptInput>;
  component?: InputMaybe<Array<ObservationComponentInput>>;
  dataAbsentReason?: InputMaybe<CodeableConceptInput>;
  device?: InputMaybe<ReferenceInput>;
  effective?: InputMaybe<EffectiveInput>;
  identifier?: InputMaybe<Array<IdentifierInput>>;
  interpretation?: InputMaybe<CodeableConceptInput>;
  issued?: InputMaybe<Scalars['UTCDateTime']['input']>;
  method?: InputMaybe<CodeableConceptInput>;
  note?: InputMaybe<Array<AnnotationInput>>;
  performer?: InputMaybe<ReferenceInput>;
  referenceRange?: InputMaybe<Array<ReferenceRangeInput>>;
  specimen?: InputMaybe<ReferenceInput>;
  status?: ObservationStatus;
  subject?: InputMaybe<ReferenceInput>;
  value?: InputMaybe<ValueInput>;
};

export enum ObservationStatus {
  Amended = 'AMENDED',
  Cancelled = 'CANCELLED',
  Corrected = 'CORRECTED',
  EnteredInError = 'ENTERED_IN_ERROR',
  Final = 'FINAL',
  Preliminary = 'PRELIMINARY',
  Registered = 'REGISTERED',
  Unknown = 'UNKNOWN',
}

export type Onset = {
  __typename?: 'Onset';
  onsetAge?: Maybe<Age>;
  onsetDateTime?: Maybe<Scalars['String']['output']>;
  onsetPeriod?: Maybe<Period>;
  onsetRange?: Maybe<Range>;
  onsetString?: Maybe<Scalars['String']['output']>;
};

export type OnsetInput = {
  onsetAge?: InputMaybe<AgeInput>;
  onsetDateTime?: InputMaybe<Scalars['String']['input']>;
  onsetPeriod?: InputMaybe<PeriodInput>;
  onsetRange?: InputMaybe<RangeInput>;
  onsetString?: InputMaybe<Scalars['String']['input']>;
};

export type Organization = {
  __typename?: 'Organization';
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type OrganizationEmployee = {
  __typename?: 'OrganizationEmployee';
  birthDate: Scalars['Date']['output'];
  createdAt: Scalars['UTCDateTime']['output'];
  externalEmployeeId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  healthBenefitEligibilityStatus: OrganizationEmployeeStatus;
  id: Scalars['UUID']['output'];
  isDependent: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  organizationId: Scalars['UUID']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
  workEmail?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export enum OrganizationEmployeeStatus {
  Eligible = 'ELIGIBLE',
  Ineligible = 'INELIGIBLE',
}

export type OrganizationEmployeesFilters = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['UUID']['input']>;
};

export type OrganizationProgram = {
  __typename?: 'OrganizationProgram';
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  maxEnrollmentsPerEmployee: Scalars['Int']['output'];
  organizationId: Scalars['UUID']['output'];
  program: Program;
  programId: Scalars['UUID']['output'];
  status: OrganizationProgramStatus;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export enum OrganizationProgramStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
}

export type OrganizationProgramsFilters = {
  organizationId?: InputMaybe<Scalars['UUID']['input']>;
  programId?: InputMaybe<Scalars['UUID']['input']>;
};

export type PaginationParams = {
  pageNumber?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
};

export type Participant = {
  __typename?: 'Participant';
  actor: Reference;
  function?: Maybe<CodeableConcept>;
};

export type ParticipantInput = {
  actor: ReferenceInput;
  function?: InputMaybe<CodeableConceptInput>;
};

export enum ParticipantType {
  Agent = 'AGENT',
  Patient = 'PATIENT',
}

export type Patient = {
  __typename?: 'Patient';
  addresses?: Maybe<Array<Address>>;
  age: Scalars['Int']['output'];
  birthDate: Scalars['Date']['output'];
  birthSex: BirthSex;
  careJourneys?: Maybe<Array<CareJourney>>;
  contactPreference?: Maybe<ContactPreference>;
  createdAt: Scalars['UTCDateTime']['output'];
  emailAddresses?: Maybe<Array<EmailAddress>>;
  enrollments: Array<Enrollment>;
  firstName: Scalars['String']['output'];
  genderIdentity?: Maybe<GenderIdentity>;
  id: Scalars['UUID']['output'];
  lastName: Scalars['String']['output'];
  phoneNumbers?: Maybe<Array<PhoneNumber>>;
  preferredName?: Maybe<Scalars['String']['output']>;
  pronouns?: Maybe<Pronouns>;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type PatientAlertCounts = {
  __typename?: 'PatientAlertCounts';
  highCount: Scalars['Int']['output'];
  lowCount: Scalars['Int']['output'];
  mediumCount: Scalars['Int']['output'];
  patient: Patient;
  patientId: Scalars['UUID']['output'];
};

export enum PatientSortField {
  BirthDate = 'BIRTH_DATE',
  CreatedAt = 'CREATED_AT',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
}

export type PatientStaff = Patient | Staff;

export type PatientsHealth = {
  __typename?: 'PatientsHealth';
  status: Scalars['String']['output'];
};

export type PatientsList = {
  __typename?: 'PatientsList';
  items: Array<Patient>;
  totalCount: Scalars['Int']['output'];
};

export type Payer = {
  __typename?: 'Payer';
  eligibilityType: EligibilityType;
  organizationEmployeeId?: Maybe<Scalars['UUID']['output']>;
  organizationId: Scalars['UUID']['output'];
  type: Scalars['String']['output'];
};

export enum PayerType {
  Employer = 'EMPLOYER',
}

export type Period = {
  __typename?: 'Period';
  end?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export type PeriodInput = {
  end?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  use: ContactUse;
  value: Scalars['String']['output'];
};

export type PhoneNumberInput = {
  use: ContactUse;
  value: Scalars['String']['input'];
};

export type PlanNote = {
  __typename?: 'PlanNote';
  labs?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  prescriptions?: Maybe<Scalars['String']['output']>;
  referrals?: Maybe<Scalars['String']['output']>;
};

export type PlanNoteInput = {
  labs?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  prescriptions?: InputMaybe<Scalars['String']['input']>;
  referrals?: InputMaybe<Scalars['String']['input']>;
};

export type Practitioner = {
  __typename?: 'Practitioner';
  addresses: Array<PractitionerAddress>;
  archivedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  boardCertifications?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['UTCDateTime']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  includeInSearch: Scalars['Boolean']['output'];
  languages?: Maybe<Array<Scalars['String']['output']>>;
  lastName: Scalars['String']['output'];
  networks: Array<PractitionerNetwork>;
  npiNumber?: Maybe<Scalars['String']['output']>;
  specialties: Array<PractitionerSpecialty>;
  tenantId: Scalars['UUID']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type PractitionerAddress = {
  __typename?: 'PractitionerAddress';
  acceptingNewPatients?: Maybe<Scalars['Boolean']['output']>;
  accessible?: Maybe<Scalars['Boolean']['output']>;
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  archivedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  city: Scalars['String']['output'];
  createdAt: Scalars['UTCDateTime']['output'];
  geoLocation?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  practitionerId: Scalars['UUID']['output'];
  schedulingUrl?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  telemedicineCapable?: Maybe<Scalars['Boolean']['output']>;
  tenantId: Scalars['UUID']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
  zipCode: Scalars['String']['output'];
};

export type PractitionerNearResult = {
  __typename?: 'PractitionerNearResult';
  address: PractitionerAddress;
  distance: Scalars['Float']['output'];
  practitioner: Practitioner;
};

export type PractitionerNetwork = {
  __typename?: 'PractitionerNetwork';
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  networkId: Scalars['UUID']['output'];
  practitionerId: Scalars['UUID']['output'];
  tenantId: Scalars['UUID']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type PractitionerSearchResult = {
  __typename?: 'PractitionerSearchResult';
  displayName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
};

export type PractitionerSpecialty = {
  __typename?: 'PractitionerSpecialty';
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  practitionerId: Scalars['UUID']['output'];
  primary: Scalars['Boolean']['output'];
  specialtyId: Scalars['UUID']['output'];
  tenantId: Scalars['UUID']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type PractitionersNearList = {
  __typename?: 'PractitionersNearList';
  items: Array<PractitionerNearResult>;
  totalCount: Scalars['Int']['output'];
};

export type PrescriberNote = {
  __typename?: 'PrescriberNote';
  assessment?: Maybe<PrescriberNoteAssessmentNote>;
  asyncNote?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<PrescriberPlanNote>;
  subjectiveObjective?: Maybe<PrescriberSubjectiveObjectiveNote>;
};

export type PrescriberNoteAssessmentNote = {
  __typename?: 'PrescriberNoteAssessmentNote';
  notes?: Maybe<Scalars['String']['output']>;
};

export type PrescriberNoteAssessmentNoteInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type PrescriberNoteInput = {
  assessment?: InputMaybe<PrescriberNoteAssessmentNoteInput>;
  asyncNote?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<PrescriberPlanNoteInput>;
  subjectiveObjective?: InputMaybe<PrescriberSubjectiveObjectiveNoteInput>;
};

export type PrescriberPlanNote = {
  __typename?: 'PrescriberPlanNote';
  healthScreeningCounselling?: Maybe<Scalars['String']['output']>;
  labs?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  postVisitSummary?: Maybe<Scalars['String']['output']>;
  prescriptions?: Maybe<Scalars['String']['output']>;
  referrals?: Maybe<Scalars['String']['output']>;
};

export type PrescriberPlanNoteInput = {
  healthScreeningCounselling?: InputMaybe<Scalars['String']['input']>;
  labs?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  postVisitSummary?: InputMaybe<Scalars['String']['input']>;
  prescriptions?: InputMaybe<Scalars['String']['input']>;
  referrals?: InputMaybe<Scalars['String']['input']>;
};

export type PrescriberSubjectiveObjectiveNote = {
  __typename?: 'PrescriberSubjectiveObjectiveNote';
  historyOfPresentIllness?: Maybe<Scalars['String']['output']>;
  medicalHistory?: Maybe<Scalars['String']['output']>;
  objective?: Maybe<Scalars['String']['output']>;
  reviewOfSystems?: Maybe<Scalars['String']['output']>;
};

export type PrescriberSubjectiveObjectiveNoteInput = {
  historyOfPresentIllness?: InputMaybe<Scalars['String']['input']>;
  medicalHistory?: InputMaybe<Scalars['String']['input']>;
  objective?: InputMaybe<Scalars['String']['input']>;
  reviewOfSystems?: InputMaybe<Scalars['String']['input']>;
};

export type ProfessionalLicense = {
  __typename?: 'ProfessionalLicense';
  createdAt: Scalars['UTCDateTime']['output'];
  deletedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  licenseNumber: Scalars['String']['output'];
  licenseState: StateCode;
  status: ProfessionalLicenseStatus;
  type: ProfessionalLicenseType;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type ProfessionalLicenseFilters = {
  staffId?: InputMaybe<Scalars['UUID']['input']>;
  type?: InputMaybe<ProfessionalLicenseType>;
};

export enum ProfessionalLicenseStatus {
  Expired = 'EXPIRED',
  PendingReview = 'PENDING_REVIEW',
  Verified = 'VERIFIED',
}

export enum ProfessionalLicenseType {
  Do = 'DO',
  Lcsw = 'LCSW',
  Lpc = 'LPC',
  Md = 'MD',
  Mft = 'MFT',
  Np = 'NP',
  Phd = 'PHD',
  Psyd = 'PSYD',
}

export type Program = {
  __typename?: 'Program';
  brandingConfig?: Maybe<BrandingConfig>;
  config?: Maybe<ProgramConfigResponse>;
  createdAt: Scalars['UTCDateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: ProgramStatus;
  twilioMetadata?: Maybe<TwilioMetadata>;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type ProgramConfigInput = {
  roleNameMapping?: InputMaybe<Scalars['JSON']['input']>;
};

export type ProgramConfigResponse = {
  __typename?: 'ProgramConfigResponse';
  roleNameMapping?: Maybe<Scalars['JSON']['output']>;
};

export enum ProgramStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum PronounCode {
  HeHim = 'HE_HIM',
  Other = 'OTHER',
  SheHer = 'SHE_HER',
  TheyThem = 'THEY_THEM',
}

export type Pronouns = {
  __typename?: 'Pronouns';
  text: Scalars['String']['output'];
  value: PronounCode;
};

export type PronounsInput = {
  text?: InputMaybe<Scalars['String']['input']>;
  value: PronounCode;
};

export type PublicProgram = {
  __typename?: 'PublicProgram';
  brandingConfig?: Maybe<BrandingConfig>;
  config?: Maybe<ProgramConfigResponse>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type PublicTenant = {
  __typename?: 'PublicTenant';
  brandingConfig?: Maybe<BrandingConfig>;
  cognitoConfig?: Maybe<CognitoConfig>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  patientDomain: Scalars['String']['output'];
  programs: Array<PublicProgram>;
  schedulingType: SchedulingType;
  slug: Scalars['String']['output'];
};

export type Quantity = {
  __typename?: 'Quantity';
  code?: Maybe<Scalars['String']['output']>;
  comparator?: Maybe<Comparator>;
  system?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Decimal']['output']>;
};

export type QuantityInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  comparator?: InputMaybe<Comparator>;
  system?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Decimal']['input']>;
};

export type Query = {
  __typename?: 'Query';
  activeWebConversation?: Maybe<AgentConversation>;
  agentConversation: AgentConversation;
  agentConversationMessages: AgentMessagesList;
  agentConversations: AgentConversationsList;
  agentMessage: AgentMessage;
  alert: Alert;
  alertCountsByPatient: AlertCountsList;
  alerts: AlertList;
  allergyAutocomplete: Array<AllergyAutocompleteResult>;
  allergyIntolerance: AllergyIntolerance;
  allergyIntolerances: Array<AllergyIntolerance>;
  appointment: Appointment;
  appointmentType: AppointmentType;
  appointmentTypeByProgramAndSlug: AppointmentType;
  appointmentTypes: Array<AppointmentType>;
  appointments: Array<Appointment>;
  careJourney: CareJourney;
  careJourneyHealth: CareJourneyHealth;
  careJourneyTemplate: CareJourneyTemplate;
  careJourneyTemplateVersion: CareJourneyTemplateVersion;
  careJourneyTemplates: Array<CareJourneyTemplate>;
  careJourneys: Array<CareJourney>;
  checkEmploymentEligibility: CheckEmploymentEligibilityOutput;
  conceptsPractitionersAutocomplete: ConceptsPractitionersSearchResults;
  condition: Condition;
  conditions: Array<Condition>;
  contentHealth: ContentHealth;
  contentView: ContentView;
  contentViews: Array<ContentView>;
  conversation: Conversation;
  conversationAiHealth: ConversationAiHealth;
  conversationUserAccessToken: ConversationUserAccessToken;
  cronofyProfile: CronofyProfile;
  cronofyProfiles: Array<CronofyProfile>;
  deepLinksHealth: DeepLinksHealth;
  eligibilityHealth: EligibilityHealth;
  eligibilityWithAllowance: EligibilityWithAllowance;
  encounter: Encounter;
  encounters: Array<Encounter>;
  enrollment: Enrollment;
  enrollments: Array<Enrollment>;
  facilities: Array<Facility>;
  facilitiesNear: FacilitiesNearList;
  facility: Facility;
  findConditionByCodeableConcept?: Maybe<Condition>;
  form: Form;
  formDestinationAutocomplete: Array<FormDestinationSearchResult>;
  formDestinations: Array<FormDestination>;
  formResponse: FormResponse;
  formResponseByTaskId?: Maybe<FormResponse>;
  formResponses: Array<FormResponse>;
  formVersion: FormVersion;
  forms: Array<Form>;
  formsHealth: FormsHealth;
  health: TenantsHealth;
  healthAlertsHealth: HealthAlertsHealth;
  healthDataHealth: HealthDataHealth;
  icd10Autocomplete: Array<Icd10AutocompleteResult>;
  latestObservation?: Maybe<Observation>;
  medicationAutocomplete: MedicationAutocompleteResult;
  medicationLookup: Array<MedicationLookupResult>;
  medicationStatement: MedicationStatement;
  medicationStatements: Array<MedicationStatement>;
  messagingHealth: MessagingHealth;
  monitor: Monitor;
  monitors: MonitorList;
  network: Network;
  networks: Array<Network>;
  notificationsHealth: NotificationHealth;
  observation: Observation;
  observationDefinition: ObservationDefinition;
  observationDefinitionAutocomplete: Array<ObservationDefinitionAutocompleteResult>;
  observationDefinitionBySlug?: Maybe<ObservationDefinition>;
  observationDefinitions: Array<ObservationDefinition>;
  observations: Array<Observation>;
  organization: Organization;
  organizationEmployee: OrganizationEmployee;
  organizationEmployees: Array<OrganizationEmployee>;
  organizationProgram: OrganizationProgram;
  organizationPrograms: Array<OrganizationProgram>;
  organizations: Array<Organization>;
  patient: Patient;
  patientMember: Member;
  patients: PatientsList;
  patientsHealth: PatientsHealth;
  practitioner: Practitioner;
  practitionerAddress?: Maybe<PractitionerAddress>;
  practitionerAddresses: Array<PractitionerAddress>;
  practitionerNetwork: PractitionerNetwork;
  practitionerNetworks: Array<PractitionerNetwork>;
  practitionerSpecialties: Array<PractitionerSpecialty>;
  practitionerSpecialty: PractitionerSpecialty;
  practitioners: Array<Practitioner>;
  practitionersNear: PractitionersNearList;
  professionalLicense: ProfessionalLicense;
  professionalLicenses: Array<ProfessionalLicense>;
  program: Program;
  programs: Array<Program>;
  resolveDeepLink: DeepLinkResponse;
  schedulingHealth: SchedulingHealth;
  sequentialTouchpoint: SequentialTouchpoint;
  sequentialTouchpointByTaskId?: Maybe<SequentialTouchpoint>;
  sequentialTouchpointTemplate: SequentialTouchpointTemplate;
  sequentialTouchpointTemplates: Array<SequentialTouchpointTemplate>;
  sequentialTouchpoints: SequentialTouchpointList;
  specialties: Array<Specialty>;
  specialty: Specialty;
  staff: Staff;
  staffHealth: StaffHealth;
  staffProgram: StaffProgram;
  staffPrograms: Array<StaffProgram>;
  staffWhoAmI: Staff;
  staffs: Array<Staff>;
  task: Task;
  taskTemplate: TaskTemplate;
  taskTemplateBySlug: TaskTemplate;
  taskTemplates: Array<TaskTemplate>;
  tasks: TasksList;
  tasksHealth: TasksHealth;
  templateTags: Array<TemplateTag>;
  tenant: Tenant;
  tenantByDomain?: Maybe<PublicTenant>;
  tenantByUserPoolId?: Maybe<PublicTenant>;
  tenants: Array<Tenant>;
  treatmentHealth: TreatmentHealth;
  validateValues: Array<ValidateObservationResult>;
  whoAmI: Patient;
};

export type QueryActiveWebConversationArgs = {
  input: ActiveWebConversationInput;
};

export type QueryAgentConversationArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryAgentConversationMessagesArgs = {
  conversationId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationParams>;
  sorting?: InputMaybe<Array<SortMessageParam>>;
};

export type QueryAgentConversationsArgs = {
  filters?: InputMaybe<AgentConversationFilters>;
  pagination?: InputMaybe<PaginationParams>;
  sorting?: InputMaybe<Array<SortConversationParam>>;
};

export type QueryAgentMessageArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryAlertArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryAlertCountsByPatientArgs = {
  pagination?: InputMaybe<PaginationParams>;
};

export type QueryAlertsArgs = {
  filters?: InputMaybe<ListAlertFilters>;
  pagination?: InputMaybe<PaginationParams>;
  sorting?: InputMaybe<Array<SortAlertParam>>;
};

export type QueryAllergyAutocompleteArgs = {
  query: Scalars['String']['input'];
};

export type QueryAllergyIntoleranceArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryAllergyIntolerancesArgs = {
  filters: AllergyIntoleranceFilters;
};

export type QueryAppointmentArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryAppointmentTypeArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryAppointmentTypeByProgramAndSlugArgs = {
  programId: Scalars['UUID']['input'];
  slug: Scalars['String']['input'];
};

export type QueryAppointmentTypesArgs = {
  input?: InputMaybe<ListAppointmentTypesInput>;
};

export type QueryAppointmentsArgs = {
  input?: InputMaybe<ListAppointmentsInput>;
  sorting?: InputMaybe<Array<SortAppointmentParams>>;
};

export type QueryCareJourneyArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryCareJourneyTemplateArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryCareJourneyTemplateVersionArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryCareJourneyTemplatesArgs = {
  filters: CareJourneyTemplateFilters;
};

export type QueryCareJourneysArgs = {
  filters: FilterCareJourneyParams;
};

export type QueryCheckEmploymentEligibilityArgs = {
  checkEmploymentEligibilityInput: CheckEmploymentEligibilityInput;
  programId: Scalars['UUID']['input'];
};

export type QueryConceptsPractitionersAutocompleteArgs = {
  query: Scalars['String']['input'];
};

export type QueryConditionArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryConditionsArgs = {
  filters: ConditionFilters;
};

export type QueryContentViewArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryContentViewsArgs = {
  filters?: InputMaybe<ListContentViewFilters>;
};

export type QueryConversationArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryCronofyProfileArgs = {
  staffId: Scalars['UUID']['input'];
};

export type QueryEligibilityWithAllowanceArgs = {
  input: EligibilityCheckInput;
  programId: Scalars['UUID']['input'];
};

export type QueryEncounterArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryEncountersArgs = {
  filters?: InputMaybe<ListEncounterFilters>;
};

export type QueryEnrollmentArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryEnrollmentsArgs = {
  filters: EnrollmentFilters;
};

export type QueryFacilitiesNearArgs = {
  filters: FilterNearFacilitiesParams;
  pagination?: InputMaybe<PaginationParams>;
};

export type QueryFacilityArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryFindConditionByCodeableConceptArgs = {
  filters: CodeableConceptFilters;
};

export type QueryFormArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryFormDestinationAutocompleteArgs = {
  programId?: InputMaybe<Scalars['UUID']['input']>;
  query: Scalars['String']['input'];
};

export type QueryFormDestinationsArgs = {
  filters: FormDestinationFilters;
};

export type QueryFormResponseArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryFormResponseByTaskIdArgs = {
  taskId: Scalars['UUID']['input'];
};

export type QueryFormResponsesArgs = {
  filters: FormResponseFilters;
};

export type QueryFormVersionArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryFormsArgs = {
  filters: FormFilters;
};

export type QueryIcd10AutocompleteArgs = {
  query: Scalars['String']['input'];
};

export type QueryLatestObservationArgs = {
  filters: ObservationFilters;
};

export type QueryMedicationAutocompleteArgs = {
  query: Scalars['String']['input'];
};

export type QueryMedicationLookupArgs = {
  query: Scalars['String']['input'];
};

export type QueryMedicationStatementArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryMedicationStatementsArgs = {
  filters: MedicationStatementFilters;
};

export type QueryMonitorArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryMonitorsArgs = {
  filters?: InputMaybe<ListMonitorFilters>;
  pagination?: InputMaybe<PaginationParams>;
  sorting?: InputMaybe<Array<SortMonitorParam>>;
};

export type QueryNetworkArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryObservationArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryObservationDefinitionArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryObservationDefinitionAutocompleteArgs = {
  programId?: InputMaybe<Scalars['UUID']['input']>;
  query: Scalars['String']['input'];
};

export type QueryObservationDefinitionBySlugArgs = {
  programId: Scalars['UUID']['input'];
  slug: Scalars['String']['input'];
};

export type QueryObservationDefinitionsArgs = {
  filters: ObservationDefinitionFilters;
};

export type QueryObservationsArgs = {
  filters: ObservationFilters;
};

export type QueryOrganizationArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryOrganizationEmployeeArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryOrganizationEmployeesArgs = {
  args: OrganizationEmployeesFilters;
};

export type QueryOrganizationProgramArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryOrganizationProgramsArgs = {
  args: OrganizationProgramsFilters;
};

export type QueryPatientArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryPatientMemberArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryPatientsArgs = {
  filters?: InputMaybe<FilterPatientParams>;
  pagination?: InputMaybe<PaginationParams>;
  sorting?: InputMaybe<Array<SortPatientParam>>;
};

export type QueryPractitionerArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryPractitionerAddressArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryPractitionerAddressesArgs = {
  practitionerId: Scalars['UUID']['input'];
};

export type QueryPractitionerNetworkArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryPractitionerNetworksArgs = {
  filterParams: FilterPractitionerNetworkParams;
};

export type QueryPractitionerSpecialtiesArgs = {
  filterParams: FilterPractitionerSpecialtyParams;
};

export type QueryPractitionerSpecialtyArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryPractitionersNearArgs = {
  filters: FilterNearPractitionerParams;
  pagination?: InputMaybe<PaginationParams>;
};

export type QueryProfessionalLicenseArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryProfessionalLicensesArgs = {
  filters: ProfessionalLicenseFilters;
};

export type QueryProgramArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryResolveDeepLinkArgs = {
  token: Scalars['String']['input'];
};

export type QuerySequentialTouchpointArgs = {
  id: Scalars['UUID']['input'];
};

export type QuerySequentialTouchpointByTaskIdArgs = {
  taskId: Scalars['UUID']['input'];
};

export type QuerySequentialTouchpointTemplateArgs = {
  id: Scalars['UUID']['input'];
};

export type QuerySequentialTouchpointTemplatesArgs = {
  filters?: InputMaybe<FilterSequentialTouchpointTemplateParams>;
};

export type QuerySequentialTouchpointsArgs = {
  filters: FilterSequentialTouchpointParams;
  pagination?: InputMaybe<PaginationParams>;
  sorting?: InputMaybe<Array<SortTouchpointParam>>;
};

export type QuerySpecialtiesArgs = {
  filters: SpecialtyFilters;
};

export type QuerySpecialtyArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryStaffArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryStaffProgramArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryStaffProgramsArgs = {
  filters: StaffProgramFilters;
};

export type QueryStaffsArgs = {
  input?: InputMaybe<ListStaffsInput>;
};

export type QueryTaskArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryTaskTemplateArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryTaskTemplateBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryTaskTemplatesArgs = {
  filters: FilterTaskTemplateParams;
};

export type QueryTasksArgs = {
  filters?: InputMaybe<FilterTaskParams>;
  pagination?: InputMaybe<TaskPaginationParams>;
  sorting?: InputMaybe<Array<SortTaskParam>>;
};

export type QueryTenantArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryTenantByDomainArgs = {
  domain: Scalars['String']['input'];
};

export type QueryTenantByUserPoolIdArgs = {
  userPoolId: Scalars['String']['input'];
};

export type QueryTenantsArgs = {
  filters: TenantFilters;
};

export type QueryValidateValuesArgs = {
  input: BatchValidateValuesInput;
};

export type QuestionAndResponse = {
  __typename?: 'QuestionAndResponse';
  components?: Maybe<Array<QuestionAndResponseComponent>>;
  destination: Scalars['String']['output'];
  displayResponse?: Maybe<Scalars['String']['output']>;
  question: Scalars['String']['output'];
  response?: Maybe<Scalars['JSON']['output']>;
  validationErrors?: Maybe<Array<Scalars['String']['output']>>;
  validationSuccess: Scalars['Boolean']['output'];
};

export type QuestionAndResponseComponent = {
  __typename?: 'QuestionAndResponseComponent';
  displayResponse: Scalars['String']['output'];
  question?: Maybe<Scalars['String']['output']>;
  response: Scalars['JSON']['output'];
};

export type Range = {
  __typename?: 'Range';
  high?: Maybe<SimpleQuantity>;
  low?: Maybe<SimpleQuantity>;
};

export type RangeInput = {
  high?: InputMaybe<SimpleQuantityInput>;
  low?: InputMaybe<SimpleQuantityInput>;
};

export type Ratio = {
  __typename?: 'Ratio';
  denominator?: Maybe<SimpleQuantity>;
  numerator?: Maybe<Quantity>;
};

export type RatioInput = {
  denominator?: InputMaybe<SimpleQuantityInput>;
  numerator?: InputMaybe<QuantityInput>;
};

export type ReCreateDeepLinkInput = {
  previousDeepLinkToken: Scalars['String']['input'];
};

export type Reaction = {
  __typename?: 'Reaction';
  description?: Maybe<Scalars['String']['output']>;
  exposureRoute?: Maybe<CodeableConcept>;
  manifestation: Array<CodeableReference>;
  note?: Maybe<Array<Annotation>>;
  onset?: Maybe<Scalars['String']['output']>;
  severity?: Maybe<AllergyIntoleranceServerity>;
  substance?: Maybe<CodeableConcept>;
};

export type ReactionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  exposureRoute?: InputMaybe<CodeableConceptInput>;
  manifestation: Array<CodeableReferenceInput>;
  note?: InputMaybe<Array<AnnotationInput>>;
  onset?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<AllergyIntoleranceServerity>;
  substance?: InputMaybe<CodeableConceptInput>;
};

export type Reference = {
  __typename?: 'Reference';
  display?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Identifier>;
  reference?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ResourceType>;
};

export type ReferenceInput = {
  display?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<IdentifierInput>;
  reference?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ResourceType>;
};

export type ReferenceRange = {
  __typename?: 'ReferenceRange';
  age?: Maybe<Range>;
  appliesTo?: Maybe<CodeableConcept>;
  high?: Maybe<SimpleQuantity>;
  low?: Maybe<SimpleQuantity>;
  normalValue?: Maybe<CodeableConcept>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CodeableConcept>;
};

export type ReferenceRangeInput = {
  age?: InputMaybe<RangeInput>;
  appliesTo?: InputMaybe<CodeableConceptInput>;
  high?: InputMaybe<SimpleQuantityInput>;
  low?: InputMaybe<SimpleQuantityInput>;
  normalValue?: InputMaybe<CodeableConceptInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CodeableConceptInput>;
};

export enum ResourceType {
  Account = 'ACCOUNT',
  ActivityDefinition = 'ACTIVITY_DEFINITION',
  ActorDefinition = 'ACTOR_DEFINITION',
  AdministrableProductDefinition = 'ADMINISTRABLE_PRODUCT_DEFINITION',
  AdverseEvent = 'ADVERSE_EVENT',
  AllergyIntolerance = 'ALLERGY_INTOLERANCE',
  Appointment = 'APPOINTMENT',
  AppointmentResponse = 'APPOINTMENT_RESPONSE',
  ArtifactAssessment = 'ARTIFACT_ASSESSMENT',
  AuditEvent = 'AUDIT_EVENT',
  Basic = 'BASIC',
  Binary = 'BINARY',
  BiologicallyDerivedProduct = 'BIOLOGICALLY_DERIVED_PRODUCT',
  BiologicallyDerivedProductDispense = 'BIOLOGICALLY_DERIVED_PRODUCT_DISPENSE',
  BodyStructure = 'BODY_STRUCTURE',
  Bundle = 'BUNDLE',
  CapabilityStatement = 'CAPABILITY_STATEMENT',
  CarePlan = 'CARE_PLAN',
  CareTeam = 'CARE_TEAM',
  ChargeItem = 'CHARGE_ITEM',
  ChargeItemDefinition = 'CHARGE_ITEM_DEFINITION',
  Citation = 'CITATION',
  Claim = 'CLAIM',
  ClaimResponse = 'CLAIM_RESPONSE',
  ClinicalImpression = 'CLINICAL_IMPRESSION',
  ClinicalUseDefinition = 'CLINICAL_USE_DEFINITION',
  CodeSystem = 'CODE_SYSTEM',
  Communication = 'COMMUNICATION',
  CommunicationRequest = 'COMMUNICATION_REQUEST',
  CompartmentDefinition = 'COMPARTMENT_DEFINITION',
  Composition = 'COMPOSITION',
  ConceptMap = 'CONCEPT_MAP',
  Condition = 'CONDITION',
  ConditionDefinition = 'CONDITION_DEFINITION',
  Consent = 'CONSENT',
  Contract = 'CONTRACT',
  Coverage = 'COVERAGE',
  CoverageEligibilityRequest = 'COVERAGE_ELIGIBILITY_REQUEST',
  CoverageEligibilityResponse = 'COVERAGE_ELIGIBILITY_RESPONSE',
  DetectedIssue = 'DETECTED_ISSUE',
  Device = 'DEVICE',
  DeviceAssociation = 'DEVICE_ASSOCIATION',
  DeviceDefinition = 'DEVICE_DEFINITION',
  DeviceDispense = 'DEVICE_DISPENSE',
  DeviceMetric = 'DEVICE_METRIC',
  DeviceRequest = 'DEVICE_REQUEST',
  DeviceUsage = 'DEVICE_USAGE',
  DiagnosticReport = 'DIAGNOSTIC_REPORT',
  DocumentReference = 'DOCUMENT_REFERENCE',
  Encounter = 'ENCOUNTER',
  EncounterHistory = 'ENCOUNTER_HISTORY',
  Endpoint = 'ENDPOINT',
  EnrollmentRequest = 'ENROLLMENT_REQUEST',
  EnrollmentResponse = 'ENROLLMENT_RESPONSE',
  EpisodeOfCare = 'EPISODE_OF_CARE',
  EventDefinition = 'EVENT_DEFINITION',
  Evidence = 'EVIDENCE',
  EvidenceReport = 'EVIDENCE_REPORT',
  EvidenceVariable = 'EVIDENCE_VARIABLE',
  ExampleScenario = 'EXAMPLE_SCENARIO',
  ExplanationOfBenefit = 'EXPLANATION_OF_BENEFIT',
  FamilyMemberHistory = 'FAMILY_MEMBER_HISTORY',
  Flag = 'FLAG',
  FormularyItem = 'FORMULARY_ITEM',
  GenomicStudy = 'GENOMIC_STUDY',
  Goal = 'GOAL',
  GraphDefinition = 'GRAPH_DEFINITION',
  Group = 'GROUP',
  GuidanceResponse = 'GUIDANCE_RESPONSE',
  HealthcareService = 'HEALTHCARE_SERVICE',
  ImagingSelection = 'IMAGING_SELECTION',
  ImagingStudy = 'IMAGING_STUDY',
  Immunization = 'IMMUNIZATION',
  ImmunizationEvaluation = 'IMMUNIZATION_EVALUATION',
  ImmunizationRecommendation = 'IMMUNIZATION_RECOMMENDATION',
  ImplementationGuide = 'IMPLEMENTATION_GUIDE',
  Ingredient = 'INGREDIENT',
  InsurancePlan = 'INSURANCE_PLAN',
  InventoryItem = 'INVENTORY_ITEM',
  InventoryReport = 'INVENTORY_REPORT',
  Invoice = 'INVOICE',
  Library = 'LIBRARY',
  Linkage = 'LINKAGE',
  List = 'LIST',
  Location = 'LOCATION',
  ManufacturedItemDefinition = 'MANUFACTURED_ITEM_DEFINITION',
  Measure = 'MEASURE',
  MeasureReport = 'MEASURE_REPORT',
  Medication = 'MEDICATION',
  MedicationAdministration = 'MEDICATION_ADMINISTRATION',
  MedicationDispense = 'MEDICATION_DISPENSE',
  MedicationKnowledge = 'MEDICATION_KNOWLEDGE',
  MedicationRequest = 'MEDICATION_REQUEST',
  MedicationStatement = 'MEDICATION_STATEMENT',
  MedicinalProductDefinition = 'MEDICINAL_PRODUCT_DEFINITION',
  MessageDefinition = 'MESSAGE_DEFINITION',
  MessageHeader = 'MESSAGE_HEADER',
  MolecularSequence = 'MOLECULAR_SEQUENCE',
  NamingSystem = 'NAMING_SYSTEM',
  NutritionIntake = 'NUTRITION_INTAKE',
  NutritionOrder = 'NUTRITION_ORDER',
  NutritionProduct = 'NUTRITION_PRODUCT',
  Observation = 'OBSERVATION',
  ObservationDefinition = 'OBSERVATION_DEFINITION',
  OperationDefinition = 'OPERATION_DEFINITION',
  OperationOutcome = 'OPERATION_OUTCOME',
  Organization = 'ORGANIZATION',
  OrganizationAffiliation = 'ORGANIZATION_AFFILIATION',
  PackagedProductDefinition = 'PACKAGED_PRODUCT_DEFINITION',
  Parameters = 'PARAMETERS',
  Patient = 'PATIENT',
  PaymentNotice = 'PAYMENT_NOTICE',
  PaymentReconciliation = 'PAYMENT_RECONCILIATION',
  Permission = 'PERMISSION',
  Person = 'PERSON',
  PlanDefinition = 'PLAN_DEFINITION',
  Practitioner = 'PRACTITIONER',
  PractitionerRole = 'PRACTITIONER_ROLE',
  Procedure = 'PROCEDURE',
  Provenance = 'PROVENANCE',
  Questionnaire = 'QUESTIONNAIRE',
  QuestionnaireResponse = 'QUESTIONNAIRE_RESPONSE',
  RegulatedAuthorization = 'REGULATED_AUTHORIZATION',
  RelatedPerson = 'RELATED_PERSON',
  RequestOrchestration = 'REQUEST_ORCHESTRATION',
  Requirements = 'REQUIREMENTS',
  ResearchStudy = 'RESEARCH_STUDY',
  ResearchSubject = 'RESEARCH_SUBJECT',
  RiskAssessment = 'RISK_ASSESSMENT',
  Schedule = 'SCHEDULE',
  SearchParameter = 'SEARCH_PARAMETER',
  ServiceRequest = 'SERVICE_REQUEST',
  Slot = 'SLOT',
  Specimen = 'SPECIMEN',
  SpecimenDefinition = 'SPECIMEN_DEFINITION',
  StructureDefinition = 'STRUCTURE_DEFINITION',
  StructureMap = 'STRUCTURE_MAP',
  Subscription = 'SUBSCRIPTION',
  SubscriptionStatus = 'SUBSCRIPTION_STATUS',
  SubscriptionTopic = 'SUBSCRIPTION_TOPIC',
  Substance = 'SUBSTANCE',
  SubstanceDefinition = 'SUBSTANCE_DEFINITION',
  SubstanceNucleicAcid = 'SUBSTANCE_NUCLEIC_ACID',
  SubstancePolymer = 'SUBSTANCE_POLYMER',
  SubstanceProtein = 'SUBSTANCE_PROTEIN',
  SubstanceReferenceInformation = 'SUBSTANCE_REFERENCE_INFORMATION',
  SubstanceSourceMaterial = 'SUBSTANCE_SOURCE_MATERIAL',
  SupplyDelivery = 'SUPPLY_DELIVERY',
  SupplyRequest = 'SUPPLY_REQUEST',
  Task = 'TASK',
  TerminologyCapabilities = 'TERMINOLOGY_CAPABILITIES',
  TestPlan = 'TEST_PLAN',
  TestReport = 'TEST_REPORT',
  TestScript = 'TEST_SCRIPT',
  Transport = 'TRANSPORT',
  ValueSet = 'VALUE_SET',
  VerificationResult = 'VERIFICATION_RESULT',
  VisionPrescription = 'VISION_PRESCRIPTION',
}

export type RuleReference = {
  id?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type SoapNote = {
  __typename?: 'SOAPNote';
  assessment?: Maybe<AssessmentNote>;
  plan?: Maybe<PlanNote>;
  subjectiveObjective?: Maybe<SubjectiveObjectiveNote>;
};

export type SoapNoteHealthCoachNotePrescriberNote =
  | HealthCoachNote
  | PrescriberNote
  | SoapNote;

export type SoapNoteInput = {
  assessment?: InputMaybe<AssessmentNoteInput>;
  plan?: InputMaybe<PlanNoteInput>;
  subjectiveObjective?: InputMaybe<SubjectiveObjectiveNoteInput>;
};

export type SchedulingHealth = {
  __typename?: 'SchedulingHealth';
  status: Scalars['String']['output'];
};

export enum SchedulingType {
  Integrated = 'INTEGRATED',
  NonIntegratedProviderSearch = 'NON_INTEGRATED_PROVIDER_SEARCH',
}

export type SendVerificationEmailOutput = {
  __typename?: 'SendVerificationEmailOutput';
  status: Scalars['String']['output'];
};

export type SequentialTouchpoint = {
  __typename?: 'SequentialTouchpoint';
  careJourneyId: Scalars['UUID']['output'];
  completedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  createdAt: Scalars['UTCDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dueAt?: Maybe<Scalars['UTCDateTime']['output']>;
  endStateDate?: Maybe<Scalars['UTCDateTime']['output']>;
  id: Scalars['UUID']['output'];
  labelDate?: Maybe<Scalars['UTCDateTime']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  programId: Scalars['UUID']['output'];
  sequentialTouchpointTemplate?: Maybe<SequentialTouchpointTemplate>;
  sequentialTouchpointTemplateId: Scalars['UUID']['output'];
  status: TouchpointStatus;
  task?: Maybe<Task>;
  taskIds?: Maybe<Array<Scalars['UUID']['output']>>;
  tasks?: Maybe<Array<Task>>;
  touchpointTemplateAncestryId: Scalars['UUID']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type SequentialTouchpointList = {
  __typename?: 'SequentialTouchpointList';
  items: Array<SequentialTouchpoint>;
  totalCount: Scalars['Int']['output'];
};

export type SequentialTouchpointTemplate = {
  __typename?: 'SequentialTouchpointTemplate';
  ancestryId: Scalars['UUID']['output'];
  anchor?: Maybe<AnchorType>;
  anchorOffset?: Maybe<Scalars['Int']['output']>;
  anchorTouchpointTemplateId?: Maybe<Scalars['UUID']['output']>;
  archivedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  careJourneyTemplateVersionId: Scalars['UUID']['output'];
  category: TouchpointCategory;
  confirmedMessage?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['UTCDateTime']['output'];
  declinedMessage?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  initialMessage?: Maybe<Scalars['String']['output']>;
  isIncomplete: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notificationConfig?: Maybe<Array<TouchpointNotificationConfig>>;
  openUponEnrollment: Scalars['Boolean']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  programId: Scalars['UUID']['output'];
  reopenOnEventDateUpdate: Scalars['Boolean']['output'];
  rules?: Maybe<SimpleConditionCompoundCondition>;
  sourceId?: Maybe<Scalars['UUID']['output']>;
  taskConfig?: Maybe<TouchpointTemplateTaskConfig>;
  taskFrequency?: Maybe<TaskCadence>;
  taskTemplateId: Scalars['UUID']['output'];
  touchpointFrequency?: Maybe<TouchpointCadence>;
  type?: Maybe<TouchpointType>;
  updateType?: Maybe<TouchpointTemplateUpdateType>;
  updatedAt: Scalars['UTCDateTime']['output'];
  updates?: Maybe<Array<Scalars['String']['output']>>;
};

export type SimpleCondition = {
  __typename?: 'SimpleCondition';
  operands: Array<Scalars['JSON']['output']>;
  operator: SimpleOperator;
  type: ConditionType;
};

export type SimpleConditionCompoundCondition =
  | CompoundCondition
  | SimpleCondition;

export enum SimpleOperator {
  Eq = 'EQ',
  Gt = 'GT',
  Gte = 'GTE',
  In = 'IN',
  Lt = 'LT',
  Lte = 'LTE',
  Ne = 'NE',
  Nin = 'NIN',
  Regex = 'REGEX',
}

export type SimpleQuantity = {
  __typename?: 'SimpleQuantity';
  code?: Maybe<Scalars['String']['output']>;
  system?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Decimal']['output']>;
};

export type SimpleQuantityInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  system?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Decimal']['input']>;
};

export type SortAlertParam = {
  field: AlertSortField;
  order?: SortOrder;
};

export type SortAppointmentParams = {
  field: AppointmentSortField;
  order?: SortOrder;
};

export type SortConversationParam = {
  field: ConversationSortField;
  order?: SortOrder;
};

export type SortMessageParam = {
  field: MessageSortField;
  order?: SortOrder;
};

export type SortMonitorParam = {
  field: MonitorSortField;
  order?: SortOrder;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SortPatientParam = {
  field?: PatientSortField;
  order?: SortOrder;
};

export type SortTaskParam = {
  field: TaskSortField;
  order?: SortOrder;
};

export type SortTouchpointParam = {
  field: TouchpointSortField;
  order?: SortOrder;
};

export type Specialty = {
  __typename?: 'Specialty';
  archivedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  practitioners: Array<PractitionerSpecialty>;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type SpecialtyFilters = {
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Staff = {
  __typename?: 'Staff';
  authId?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['UTCDateTime']['output'];
  deaNumber?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<EmailAddress>;
  firstName: Scalars['String']['output'];
  genderIdentity?: Maybe<GenderIdentity>;
  id: Scalars['UUID']['output'];
  lastName: Scalars['String']['output'];
  npiNumber?: Maybe<Scalars['String']['output']>;
  preferredName?: Maybe<Scalars['String']['output']>;
  professionalLicenses: Array<ProfessionalLicense>;
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  programs: Array<StaffProgram>;
  pronouns?: Maybe<Pronouns>;
  role: StaffRole;
  status: StaffStatus;
  tenantLevelRoles?: Maybe<Array<TenantRole>>;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type StaffHealth = {
  __typename?: 'StaffHealth';
  status: Scalars['String']['output'];
};

export type StaffProgram = {
  __typename?: 'StaffProgram';
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['ID']['output'];
  program: Program;
  programId: Scalars['UUID']['output'];
  status: StaffProgramStatus;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type StaffProgramFilters = {
  programId?: InputMaybe<Scalars['UUID']['input']>;
  staffId?: InputMaybe<Scalars['UUID']['input']>;
};

export enum StaffProgramStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum StaffRole {
  Admin = 'ADMIN',
  HealthCoach = 'HEALTH_COACH',
  Owner = 'OWNER',
  Prescriber = 'PRESCRIBER',
  Support = 'SUPPORT',
  Therapist = 'THERAPIST',
}

export enum StaffStatus {
  Deactivated = 'DEACTIVATED',
  Pending = 'PENDING',
  UnsyncMetadata = 'UNSYNC_METADATA',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
}

export type Stage = {
  __typename?: 'Stage';
  assessment?: Maybe<Array<Reference>>;
  summary?: Maybe<CodeableConcept>;
  type?: Maybe<CodeableConcept>;
};

export type StageInput = {
  assessment?: InputMaybe<Array<ReferenceInput>>;
  summary?: InputMaybe<CodeableConceptInput>;
  type?: InputMaybe<CodeableConceptInput>;
};

export enum StateCode {
  Aa = 'AA',
  Ae = 'AE',
  Ak = 'AK',
  Al = 'AL',
  Ap = 'AP',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Fm = 'FM',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mh = 'MH',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Pw = 'PW',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
}

export type SubjectiveObjectiveNote = {
  __typename?: 'SubjectiveObjectiveNote';
  historyOfPresentIllness?: Maybe<Scalars['String']['output']>;
  medicalHistory?: Maybe<Scalars['String']['output']>;
  mentalStatus?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['String']['output']>;
  reviewOfSystems?: Maybe<Scalars['String']['output']>;
};

export type SubjectiveObjectiveNoteInput = {
  historyOfPresentIllness?: InputMaybe<Scalars['String']['input']>;
  medicalHistory?: InputMaybe<Scalars['String']['input']>;
  mentalStatus?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['String']['input']>;
  reviewOfSystems?: InputMaybe<Scalars['String']['input']>;
};

export type Task = {
  __typename?: 'Task';
  assignee: PatientStaff;
  assigneeId: Scalars['UUID']['output'];
  category: TaskCategory;
  comments?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['UTCDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  eventDueAt?: Maybe<Scalars['UTCDateTime']['output']>;
  id: Scalars['UUID']['output'];
  legacyNotificationSchedule?: Maybe<NotificationSchedule>;
  name: Scalars['String']['output'];
  notificationConfig?: Maybe<Array<NotificationConfig>>;
  patient: Patient;
  patientId: Scalars['UUID']['output'];
  priority: TaskPriority;
  program: Program;
  programId: Scalars['UUID']['output'];
  relatedObjectId?: Maybe<Scalars['String']['output']>;
  relatedObjectType?: Maybe<TaskRelatedObject>;
  reporter?: Maybe<Staff>;
  reporterId?: Maybe<Scalars['UUID']['output']>;
  resultObject?: Maybe<FormResponseAppointmentContentView>;
  resultObjectId?: Maybe<Scalars['UUID']['output']>;
  resultObjectType?: Maybe<TaskResultObject>;
  role?: Maybe<StaffRole>;
  status: TaskStatus;
  taskConfig?: Maybe<TaskConfig>;
  taskDueAt: Scalars['UTCDateTime']['output'];
  taskExpireAt?: Maybe<Scalars['UTCDateTime']['output']>;
  taskTemplate?: Maybe<TaskTemplate>;
  taskTemplateId?: Maybe<Scalars['UUID']['output']>;
  taskType: TaskType;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type TaskCadence = {
  __typename?: 'TaskCadence';
  frequency: Frequency;
  numberOfRepeats?: Maybe<Scalars['Int']['output']>;
};

export type TaskCadenceInput = {
  frequency: FrequencyInput;
  numberOfRepeats?: InputMaybe<Scalars['Int']['input']>;
};

export enum TaskCategory {
  CareTeam = 'CARE_TEAM',
  Patient = 'PATIENT',
}

export type TaskConfig = {
  __typename?: 'TaskConfig';
  earliestBookingDate?: Maybe<Scalars['Date']['output']>;
  type: TaskType;
};

export type TaskConfigInput = {
  earliestBookingDate?: InputMaybe<Scalars['Date']['input']>;
  type: TaskType;
};

export type TaskPaginationParams = {
  pageNumber?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
};

export enum TaskPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

export enum TaskRelatedObject {
  AppointmentType = 'APPOINTMENT_TYPE',
  Content = 'CONTENT',
  Conversation = 'CONVERSATION',
  Form = 'FORM',
  FormResponse = 'FORM_RESPONSE',
  LabOrder = 'LAB_ORDER',
  Miscellaneous = 'MISCELLANEOUS',
  Specialty = 'SPECIALTY',
}

export enum TaskResultObject {
  Appointment = 'APPOINTMENT',
  ContentView = 'CONTENT_VIEW',
  FormResponse = 'FORM_RESPONSE',
  LabOrderResult = 'LAB_ORDER_RESULT',
  Message = 'MESSAGE',
  Miscellaneous = 'MISCELLANEOUS',
}

export enum TaskSortField {
  EventDueAt = 'EVENT_DUE_AT',
  Name = 'NAME',
  Priority = 'PRIORITY',
  Status = 'STATUS',
  TaskDueAt = 'TASK_DUE_AT',
  TaskExpireAt = 'TASK_EXPIRE_AT',
}

export enum TaskStatus {
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  InProgress = 'IN_PROGRESS',
  Open = 'OPEN',
}

export type TaskTemplate = {
  __typename?: 'TaskTemplate';
  archivedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  category: TaskCategory;
  createdAt: Scalars['UTCDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  eventDueDuration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  notificationConfig?: Maybe<NotificationReminderConfig>;
  priority: TaskPriority;
  programId: Scalars['UUID']['output'];
  relatedObjectId?: Maybe<Scalars['String']['output']>;
  relatedObjectType?: Maybe<TaskRelatedObject>;
  resultObjectType?: Maybe<TaskResultObject>;
  role?: Maybe<StaffRole>;
  slug: Scalars['String']['output'];
  taskConfig?: Maybe<TaskConfig>;
  taskDueDuration: Scalars['Int']['output'];
  taskExpirationDuration?: Maybe<Scalars['Int']['output']>;
  taskType: TaskType;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export enum TaskType {
  CareCoordination = 'CARE_COORDINATION',
  CheckInReminder = 'CHECK_IN_REMINDER',
  CompletePatientForm = 'COMPLETE_PATIENT_FORM',
  Confirmation = 'CONFIRMATION',
  CreateLabOrder = 'CREATE_LAB_ORDER',
  CreateMessage = 'CREATE_MESSAGE',
  CreateRx = 'CREATE_RX',
  Labs = 'LABS',
  Miscellaneous = 'MISCELLANEOUS',
  RefillRx = 'REFILL_RX',
  ReviewContent = 'REVIEW_CONTENT',
  ReviewFormResponse = 'REVIEW_FORM_RESPONSE',
  ReviewMessage = 'REVIEW_MESSAGE',
  ScheduleAppointment = 'SCHEDULE_APPOINTMENT',
  ScheduleNonIntegratedAppointment = 'SCHEDULE_NON_INTEGRATED_APPOINTMENT',
  TechIssue = 'TECH_ISSUE',
}

export type TasksHealth = {
  __typename?: 'TasksHealth';
  status: Scalars['String']['output'];
};

export type TasksList = {
  __typename?: 'TasksList';
  items: Array<Task>;
  totalCount: Scalars['Int']['output'];
};

export type TemplateTag = {
  __typename?: 'TemplateTag';
  name: Scalars['String']['output'];
};

export type Tenant = {
  __typename?: 'Tenant';
  agentConfig?: Maybe<AgentConfig>;
  brandingConfig?: Maybe<BrandingConfig>;
  cognitoConfig?: Maybe<CognitoConfig>;
  createdAt: Scalars['UTCDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  patientDomain: Scalars['String']['output'];
  schedulingType: SchedulingType;
  slug: Scalars['String']['output'];
  status: TenantStatus;
  twilioConfig?: Maybe<TwilioConfig>;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type TenantFilters = {
  schedulingType?: InputMaybe<SchedulingType>;
  slug?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum TenantRole {
  Admin = 'ADMIN',
  Owner = 'OWNER',
  Support = 'SUPPORT',
}

export enum TenantStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type TenantsHealth = {
  __typename?: 'TenantsHealth';
  status: Scalars['String']['output'];
};

export type ThirdPartyAdministrator = {
  __typename?: 'ThirdPartyAdministrator';
  createdAt: Scalars['UTCDateTime']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  tenantId: Scalars['UUID']['output'];
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type Timing = {
  __typename?: 'Timing';
  code?: Maybe<CodeableConcept>;
  event?: Maybe<Array<Scalars['UTCDateTime']['output']>>;
};

export type TimingInput = {
  code?: InputMaybe<CodeableConceptInput>;
  event?: InputMaybe<Array<Scalars['UTCDateTime']['input']>>;
};

export type TouchpointCadence = {
  __typename?: 'TouchpointCadence';
  frequency: Frequency;
  numberOfRepeats?: Maybe<Scalars['Int']['output']>;
};

export type TouchpointCadenceInput = {
  frequency: FrequencyInput;
  numberOfRepeats?: InputMaybe<Scalars['Int']['input']>;
};

export enum TouchpointCategory {
  Appointment = 'APPOINTMENT',
  Confirmation = 'CONFIRMATION',
  Content = 'CONTENT',
  Form = 'FORM',
  HealthNotification = 'HEALTH_NOTIFICATION',
  Lab = 'LAB',
  NonIntegratedAppointment = 'NON_INTEGRATED_APPOINTMENT',
  Other = 'OTHER',
  ProgramOptin = 'PROGRAM_OPTIN',
  RemoteMonitoring = 'REMOTE_MONITORING',
}

export type TouchpointNotificationConfig = {
  __typename?: 'TouchpointNotificationConfig';
  delay?: Maybe<TouchpointNotificationDuration>;
  plainTextBody: Scalars['String']['output'];
  plainTextRecurrenceBody?: Maybe<Scalars['String']['output']>;
};

export type TouchpointNotificationConfigInput = {
  delay?: InputMaybe<TouchpointNotificationDurationInput>;
  plainTextBody: Scalars['String']['input'];
  plainTextRecurrenceBody?: InputMaybe<Scalars['String']['input']>;
};

export type TouchpointNotificationDuration = {
  __typename?: 'TouchpointNotificationDuration';
  unit: DurationUnit;
  value: Scalars['Int']['output'];
};

export type TouchpointNotificationDurationInput = {
  unit: DurationUnit;
  value: Scalars['Int']['input'];
};

export type TouchpointNotificationReminderCondition = {
  __typename?: 'TouchpointNotificationReminderCondition';
  attributes?: Maybe<Scalars['JSON']['output']>;
  eventName: Scalars['String']['output'];
  field: Scalars['String']['output'];
  sequentialOrder: Scalars['Int']['output'];
  timeDelay: Scalars['Int']['output'];
};

export type TouchpointNotificationReminderConditionInput = {
  attributes?: InputMaybe<Scalars['JSON']['input']>;
  eventName: Scalars['String']['input'];
  field: Scalars['String']['input'];
  sequentialOrder: Scalars['Int']['input'];
  timeDelay: Scalars['Int']['input'];
};

export enum TouchpointSortField {
  DueAt = 'DUE_AT',
  EndStateDate = 'END_STATE_DATE',
  Position = 'POSITION',
  UpdatedAt = 'UPDATED_AT',
}

export enum TouchpointStatus {
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  CompletedInPrevJourney = 'COMPLETED_IN_PREV_JOURNEY',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  InProgress = 'IN_PROGRESS',
  Locked = 'LOCKED',
  Open = 'OPEN',
}

export type TouchpointTemplateTaskConfig = {
  __typename?: 'TouchpointTemplateTaskConfig';
  eventDueDuration?: Maybe<Scalars['Int']['output']>;
  legacyNotificationConfig?: Maybe<LegacyNotificationReminderConfig>;
  relatedObjectId?: Maybe<Scalars['String']['output']>;
  taskDueDuration?: Maybe<Scalars['Int']['output']>;
  taskExpirationDuration?: Maybe<Scalars['Int']['output']>;
};

export type TouchpointTemplateTaskConfigInput = {
  eventDueDuration?: InputMaybe<Scalars['Int']['input']>;
  legacyNotificationConfig?: InputMaybe<LegacyNotificationReminderConfigInput>;
  relatedObjectId?: InputMaybe<Scalars['String']['input']>;
  taskDueDuration?: InputMaybe<Scalars['Int']['input']>;
  taskExpirationDuration?: InputMaybe<Scalars['Int']['input']>;
};

export enum TouchpointTemplateUpdateType {
  Added = 'ADDED',
  Removed = 'REMOVED',
  Updated = 'UPDATED',
}

export enum TouchpointType {
  AnytimeAsNeeded = 'ANYTIME_AS_NEEDED',
  PlannedAutomatic = 'PLANNED_AUTOMATIC',
}

export type TreatmentHealth = {
  __typename?: 'TreatmentHealth';
  status: Scalars['String']['output'];
};

export type TriggerNotificationInput = {
  patientId: Scalars['UUID']['input'];
  plainTextBody: Scalars['String']['input'];
  programId: Scalars['UUID']['input'];
  taskId?: InputMaybe<Scalars['UUID']['input']>;
  taskType?: InputMaybe<TaskType>;
  tenantId: Scalars['UUID']['input'];
};

export type TwilioConfig = {
  __typename?: 'TwilioConfig';
  accountSid: Scalars['String']['output'];
  apiKey: Scalars['String']['output'];
  apiSecretPath: Scalars['String']['output'];
  authToken: Scalars['String']['output'];
  patientRoleSid: Scalars['String']['output'];
  senderPhoneNumber: Scalars['String']['output'];
  serviceSid: Scalars['String']['output'];
  staffRoleSid: Scalars['String']['output'];
};

export type TwilioConfigInput = {
  accountSid: Scalars['String']['input'];
  apiKey: Scalars['String']['input'];
  apiSecretPath: Scalars['String']['input'];
  authToken: Scalars['String']['input'];
  patientRoleSid: Scalars['String']['input'];
  senderPhoneNumber: Scalars['String']['input'];
  serviceSid: Scalars['String']['input'];
  staffRoleSid: Scalars['String']['input'];
};

export type TwilioMetadata = {
  __typename?: 'TwilioMetadata';
  senderPhoneNumber: Scalars['String']['output'];
};

export type TwilioMetadataInput = {
  senderPhoneNumber: Scalars['String']['input'];
};

export type UpdateAllergyIntoleranceInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  resource?: InputMaybe<AllergyIntoleranceResourceInput>;
};

export type UpdateAppointmentInput = {
  preFormTaskId?: InputMaybe<Scalars['UUID']['input']>;
  startTime?: InputMaybe<Scalars['UTCDateTime']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCareJourney = {
  __typename?: 'UpdateCareJourney';
  careJourneyTemplateVersionId: Scalars['UUID']['output'];
  createdAt: Scalars['UTCDateTime']['output'];
  enrollmentDate?: Maybe<Scalars['UTCDateTime']['output']>;
  eventDate?: Maybe<Scalars['UTCDateTime']['output']>;
  id: Scalars['UUID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  offboardedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  patientId: Scalars['UUID']['output'];
  previousCareJourneyId?: Maybe<Scalars['UUID']['output']>;
  programId: Scalars['UUID']['output'];
  retiredAt?: Maybe<Scalars['UTCDateTime']['output']>;
  status: CareJourneyStatus;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type UpdateCareJourneyInput = {
  eventDate?: InputMaybe<Scalars['UTCDateTime']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  offboardedAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  previousCareJourneyId?: InputMaybe<Scalars['UUID']['input']>;
  retiredAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  status?: InputMaybe<CareJourneyStatus>;
};

export type UpdateCareJourneyTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  modality?: InputMaybe<Modality>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CareJourneyTemplateStatus>;
  touchpointTemplatePositions?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type UpdateClinicalNoteInput = {
  healthCoachNoteBody?: InputMaybe<HealthCoachNoteInput>;
  historicalNoteBody?: InputMaybe<SoapNoteInput>;
  prescriberNoteBody?: InputMaybe<PrescriberNoteInput>;
};

export type UpdateConditionInput = {
  resource?: InputMaybe<ConditionResourceInput>;
};

export type UpdateEncounterInput = {
  clinicalNote?: InputMaybe<UpdateClinicalNoteInput>;
  status?: InputMaybe<EncounterStatus>;
  summary?: InputMaybe<EncounterSummaryInput>;
};

export type UpdateEnrollmentInput = {
  enrolledUntil?: InputMaybe<Scalars['UTCDateTime']['input']>;
};

export type UpdateFormInput = {
  config?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  publish?: Scalars['Boolean']['input'];
};

export type UpdateMedicationStatementInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  resource?: InputMaybe<MedicationStatementResourceInput>;
};

export type UpdateMonitorInput = {
  audience?: Array<MonitorAudience>;
  careJourneyId?: InputMaybe<Scalars['UUID']['input']>;
  muted?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  patientMessageTemplate?: Scalars['String']['input'];
  priority?: MonitorPriority;
  programId?: InputMaybe<Scalars['UUID']['input']>;
  rules?: InputMaybe<Scalars['JSON']['input']>;
  scope?: MonitorScope;
  staffClinicalMessageTemplate?: Scalars['String']['input'];
  staffNonclinicalMessageTemplate?: Scalars['String']['input'];
  type?: MonitorType;
};

export type UpdateOrganization = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationEmployee = {
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  healthBenefitEligibilityStatus?: InputMaybe<OrganizationEmployeeStatus>;
  isDependent?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastSeenEligibilityDate?: InputMaybe<Scalars['Date']['input']>;
  /** Must be a valid email address */
  workEmail?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationProgram = {
  maxEnrollmentsPerEmployee?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<OrganizationProgramStatus>;
};

export type UpdatePatientInput = {
  addresses?: InputMaybe<Array<AddressInput>>;
  contactPreference?: InputMaybe<ContactPreference>;
  genderIdentity?: InputMaybe<GenderIdentityInput>;
  phoneNumbers?: InputMaybe<Array<PhoneNumberInput>>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<PronounsInput>;
};

export type UpdatePractitionerParams = {
  boardCertifications?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName: Scalars['String']['input'];
  includeInSearch?: Scalars['Boolean']['input'];
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName: Scalars['String']['input'];
  npiNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProfessionalLicenseInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['Date']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  licenseState?: InputMaybe<StateCode>;
  type?: InputMaybe<ProfessionalLicenseType>;
};

export type UpdateProgramInput = {
  brandingConfig?: InputMaybe<BrandingConfigInput>;
  config?: InputMaybe<ProgramConfigInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: ProgramStatus;
  twilioMetadata?: InputMaybe<TwilioMetadataInput>;
};

export type UpdateSequentialTouchpointInput = {
  completedAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  dueAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<TouchpointStatus>;
};

export type UpdateSequentialTouchpointTemplateInput = {
  anchor?: InputMaybe<AnchorType>;
  anchorOffset?: InputMaybe<Scalars['Int']['input']>;
  anchorTouchpointTemplateId?: InputMaybe<Scalars['UUID']['input']>;
  confirmedMessage?: InputMaybe<Scalars['String']['input']>;
  declinedMessage?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  initialMessage?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notificationConfig?: InputMaybe<Array<TouchpointNotificationConfigInput>>;
  openUponEnrollment?: InputMaybe<Scalars['Boolean']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  reopenOnEventDateUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  rules?: InputMaybe<Scalars['JSON']['input']>;
  taskConfig?: InputMaybe<TouchpointTemplateTaskConfigInput>;
  taskFrequency?: InputMaybe<TaskCadenceInput>;
  taskTemplateId?: InputMaybe<Scalars['UUID']['input']>;
  touchpointFrequency?: InputMaybe<TouchpointCadenceInput>;
  type?: InputMaybe<TouchpointType>;
};

export type UpdateStaffInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  deaNumber?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  genderIdentity?: InputMaybe<GenderIdentityInput>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  npiNumber?: InputMaybe<Scalars['String']['input']>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  professionalLicenses?: InputMaybe<Array<ModifyStaffProfessionalLicenseInput>>;
  pronouns?: InputMaybe<PronounsInput>;
  tenantLevelRoles?: InputMaybe<Array<TenantRole>>;
};

export type UpdateStaffProgramInput = {
  status?: InputMaybe<StaffProgramStatus>;
};

export type UpdateTaskInput = {
  assigneeId?: InputMaybe<Scalars['UUID']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  eventDueAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  legacyNotificationSchedule?: InputMaybe<NotificationScheduleInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  notificationConfig?: InputMaybe<Array<NotificationConfigInput>>;
  priority?: InputMaybe<TaskPriority>;
  relatedObjectId?: InputMaybe<Scalars['String']['input']>;
  relatedObjectType?: InputMaybe<TaskRelatedObject>;
  reporterId?: InputMaybe<Scalars['UUID']['input']>;
  resultObjectId?: InputMaybe<Scalars['UUID']['input']>;
  resultObjectType?: InputMaybe<TaskResultObject>;
  role?: InputMaybe<StaffRole>;
  status?: InputMaybe<TaskStatus>;
  taskConfig?: InputMaybe<TaskConfigInput>;
  taskDueAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  taskExpireAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
};

export type UpdateTaskTemplateInput = {
  archivedAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  eventDueDuration?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notificationConfig?: InputMaybe<NotificationReminderConfigInput>;
  priority?: InputMaybe<TaskPriority>;
  relatedObjectId?: InputMaybe<Scalars['String']['input']>;
  relatedObjectType?: InputMaybe<TaskRelatedObject>;
  resultObjectType?: InputMaybe<TaskResultObject>;
  role?: InputMaybe<StaffRole>;
  taskConfig?: InputMaybe<TaskConfigInput>;
  taskDueDuration?: InputMaybe<Scalars['Int']['input']>;
  taskExpirationDuration?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTenantInput = {
  agentConfig?: InputMaybe<AgentConfigInput>;
  brandingConfig?: InputMaybe<BrandingConfigInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schedulingType?: InputMaybe<SchedulingType>;
  status?: TenantStatus;
  twilioConfig?: InputMaybe<TwilioConfigInput>;
};

export type UpdatedSequentialTouchpoint = {
  __typename?: 'UpdatedSequentialTouchpoint';
  careJourneyId: Scalars['UUID']['output'];
  completedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  createdAt: Scalars['UTCDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dueAt?: Maybe<Scalars['UTCDateTime']['output']>;
  endStateDate?: Maybe<Scalars['UTCDateTime']['output']>;
  id: Scalars['UUID']['output'];
  labelDate?: Maybe<Scalars['UTCDateTime']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  programId: Scalars['UUID']['output'];
  sequentialTouchpointTemplateId: Scalars['UUID']['output'];
  status: TouchpointStatus;
  updatedAt: Scalars['UTCDateTime']['output'];
};

export type UpsertCronofyProfileInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
  staffId: Scalars['UUID']['input'];
};

export type UpsertFormResponseInput = {
  formVersionId: Scalars['UUID']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  patientId: Scalars['UUID']['input'];
  response?: InputMaybe<Scalars['JSON']['input']>;
  submit?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ValidateObservationResult = {
  __typename?: 'ValidateObservationResult';
  componentErrors?: Maybe<Array<Array<Scalars['String']['output']>>>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  observationDefinitionSlug: Scalars['String']['output'];
  resource?: Maybe<ObservationResource>;
  success: Scalars['Boolean']['output'];
};

export type ValidateValuesInput = {
  data: Scalars['JSON']['input'];
  observationDefinitionSlug: Scalars['String']['input'];
};

export type Value = {
  __typename?: 'Value';
  valueBoolean?: Maybe<Scalars['Boolean']['output']>;
  valueDateTime?: Maybe<Scalars['String']['output']>;
  valueInteger?: Maybe<Scalars['Int']['output']>;
  valuePeriod?: Maybe<Period>;
  valueQuantity?: Maybe<Quantity>;
  valueRange?: Maybe<Range>;
  valueRatio?: Maybe<Ratio>;
  valueString?: Maybe<Scalars['String']['output']>;
  valueTime?: Maybe<Scalars['String']['output']>;
};

export type ValueInput = {
  valueBoolean?: InputMaybe<Scalars['Boolean']['input']>;
  valueDateTime?: InputMaybe<Scalars['String']['input']>;
  valueInteger?: InputMaybe<Scalars['Int']['input']>;
  valuePeriod?: InputMaybe<PeriodInput>;
  valueQuantity?: InputMaybe<QuantityInput>;
  valueRange?: InputMaybe<RangeInput>;
  valueRatio?: InputMaybe<RatioInput>;
  valueString?: InputMaybe<Scalars['String']['input']>;
  valueTime?: InputMaybe<Scalars['String']['input']>;
};

export enum Join__Graph {
  CareJourneyProtected = 'CARE_JOURNEY_PROTECTED',
  CareJourneyPublic = 'CARE_JOURNEY_PUBLIC',
  ContentProtected = 'CONTENT_PROTECTED',
  ContentPublic = 'CONTENT_PUBLIC',
  ConversationAiProtected = 'CONVERSATION_AI_PROTECTED',
  ConversationAiPublic = 'CONVERSATION_AI_PUBLIC',
  DeepLinksProtected = 'DEEP_LINKS_PROTECTED',
  DeepLinksPublic = 'DEEP_LINKS_PUBLIC',
  EligibilityProtected = 'ELIGIBILITY_PROTECTED',
  EligibilityPublic = 'ELIGIBILITY_PUBLIC',
  FormsProtected = 'FORMS_PROTECTED',
  FormsPublic = 'FORMS_PUBLIC',
  HealthAlertsProtected = 'HEALTH_ALERTS_PROTECTED',
  HealthAlertsPublic = 'HEALTH_ALERTS_PUBLIC',
  HealthDataProtected = 'HEALTH_DATA_PROTECTED',
  HealthDataPublic = 'HEALTH_DATA_PUBLIC',
  MessagingProtected = 'MESSAGING_PROTECTED',
  MessagingPublic = 'MESSAGING_PUBLIC',
  NotificationsProtected = 'NOTIFICATIONS_PROTECTED',
  NotificationsPublic = 'NOTIFICATIONS_PUBLIC',
  PatientsProtected = 'PATIENTS_PROTECTED',
  PatientsPublic = 'PATIENTS_PUBLIC',
  SchedulingProtected = 'SCHEDULING_PROTECTED',
  SchedulingPublic = 'SCHEDULING_PUBLIC',
  StaffProtected = 'STAFF_PROTECTED',
  StaffPublic = 'STAFF_PUBLIC',
  TasksProtected = 'TASKS_PROTECTED',
  TasksPublic = 'TASKS_PUBLIC',
  TenantsProtected = 'TENANTS_PROTECTED',
  TenantsPublic = 'TENANTS_PUBLIC',
  TreatmentProtected = 'TREATMENT_PROTECTED',
  TreatmentPublic = 'TREATMENT_PUBLIC',
}

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY',
}
